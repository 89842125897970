import React from 'react';
import PoolsTableWrapper from '../components/PoolsTableWrapper';
import RookWrapper from '../components/RookWrapper';
import EarnersTableWrapper from '../components/EarnersTableWrapper';

function Dashboard(): React.ReactElement {
    return (
        <div className="page-content">
            <div className="container">
                <div className="container-row">
                    <PoolsTableWrapper />
                </div>
                <div className="container-row">
                    <RookWrapper />
                    <EarnersTableWrapper />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
