import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { Token, displayTokenBalance } from '../lib/tokens';
import { contractAddress, contractTypeFromToken, ContractType, contract, distibutorContract } from '../lib/contracts';
import { useWeb3React } from '@web3-react/core';
import { NetworkID, networkIdFromChainId } from '../lib/network';
import axios from 'axios';
import config from '../lib/config';

const KOVAN = 'KOVAN_';
const ROOK_SUPPLY_HARDCODED = new BigNumber('1E+6').multipliedBy('1E+18');

export enum claimerType {
    Keeper,
    LiquidityProvider1,
    LiquidityProvider2,
    PremineLP,
    Trade,
    Trade2,
}

const DEFAULT_STATE = {
    claimable: {
        keeperRewards: {
            earnings: new BigNumber(0),
            nonce: '',
            signature: '',
            type: claimerType.Keeper,
        },
        lpRewards1: {
            earnings: new BigNumber(0),
            nonce: '',
            signature: '',
            type: claimerType.LiquidityProvider1,
        },
        lpRewards2: {
            earnings: new BigNumber(0),
            nonce: '',
            signature: '',
            type: claimerType.LiquidityProvider2,
        },
        premineLpRewards: {
            earnings: new BigNumber(0),
            nonce: '',
            signature: '',
            type: claimerType.PremineLP,
        },
        tradeRewards: {
            earnings: new BigNumber(0),
            nonce: '',
            signature: '',
            type: claimerType.Trade,
        },
        trade2Rewards: {
            earnings: new BigNumber(0),
            nonce: '',
            signature: '',
            type: claimerType.Trade2,
        },
        claimedLP1Rewards: new BigNumber(0),
        claimedLP2Rewards: new BigNumber(0),
        claimedPreMineForLP: new BigNumber(0),
        claimedTradeRewards: new BigNumber(0),
        claimedTrade2Rewards: new BigNumber(0),
    },
    rookBalance: new BigNumber(0),
    rookSupply: new BigNumber(0),
    currentBLock: 0,
};

export interface Rewards {
    earnings: BigNumber;
    nonce: string;
    signature: string;
    type: claimerType;
}

interface RookState {
    claimable: IClamable;
    rookBalance: BigNumber;
    rookSupply: BigNumber;
    currentBLock: number;
}
interface IClamable {
    keeperRewards: Rewards;
    lpRewards1: Rewards;
    lpRewards2: Rewards;
    premineLpRewards: Rewards;
    tradeRewards: Rewards;
    trade2Rewards: Rewards;
    claimedLP1Rewards: BigNumber | null;
    claimedLP2Rewards: BigNumber | null;
    claimedPreMineForLP: BigNumber | null;
    claimedTradeRewards: BigNumber | null;
    claimedTrade2Rewards: BigNumber | null;
}

export enum ClaimRookState {
    HIDDEN,
    WAIT_FOR_PROCEED_TO_CLAIMING,
    WAIT_FOR_APPROVE,
    WAIT_FOR_CLAIM,
    SUCCESS,
    ERROR,
    CANCEL_APPROVE,
    CANCEL_CLAIM,
}

export function calculateClaimable(
    claimable: IClamable,
): {
    totalRewardsPending: BigNumber;
    totalPreMinePending: BigNumber;
    totalHidingGamePending: BigNumber;
    totalHidingGame2Pending: BigNumber;
    totalLP1Pending: BigNumber;
    totalLP2Pending: BigNumber;
    lp1Rewards: BigNumber;
    lp2Rewards: BigNumber;
    hidingRewards: BigNumber;
    keeperRewards: BigNumber;
    premineBlock: number;
    lp1block: number;
    lp2block: number;
    hidingBlock: number;
    hiding2Block: number;
    error: string | null;
} {
    const {
        lpRewards1,
        lpRewards2,
        keeperRewards,
        tradeRewards,
        trade2Rewards,
        claimedLP1Rewards,
        claimedLP2Rewards,
        premineLpRewards,
        claimedPreMineForLP,
        claimedTradeRewards,
        claimedTrade2Rewards,
    } = claimable;
    const error = !claimedLP1Rewards ? 'DistrubuteContractV1 contract error' : null;
    const claimedLP1rewards = claimedLP1Rewards ? new BigNumber(claimedLP1Rewards) : new BigNumber(0);
    const claimedLP2rewards = claimedLP2Rewards ? new BigNumber(claimedLP2Rewards) : new BigNumber(0);
    const claimedLPPremineRewards = claimedPreMineForLP ? new BigNumber(claimedPreMineForLP) : new BigNumber(0);
    const claimedTradingRewards = claimedTradeRewards ? new BigNumber(claimedTradeRewards) : new BigNumber(0);
    const claimedTrading2Rewards = claimedTrade2Rewards ? new BigNumber(claimedTrade2Rewards) : new BigNumber(0);

    //  // Checking rewards
    // console.log('LP 1 earnings: ', displayTokenBalance(Token.ROOK, lpRewards1.earnings));
    // console.log('Claimed LP1 rewards: ', displayTokenBalance(Token.ROOK, claimedLP1rewards));
    // console.log('LP 2 earnings: ', displayTokenBalance(Token.ROOK, lpRewards2.earnings));
    // console.log('Claimed LP2 rewards: ', displayTokenBalance(Token.ROOK, claimedLP2rewards));
    // console.log('LP premine earnings: ', displayTokenBalance(Token.ROOK, premineLpRewards.earnings));
    // console.log('Claimed premine rewards: ', displayTokenBalance(Token.ROOK, claimedLPPremineRewards));
    // console.log('Hiding earnings: ', displayTokenBalance(Token.ROOK, tradeRewards.earnings));
    // console.log('Claimed hiding rewards: ', displayTokenBalance(Token.ROOK, claimedTradingRewards));
    // console.log('--------------------');
    const premineBlock = parseInt(premineLpRewards.nonce, 16);
    const lp1block = parseInt(lpRewards1.nonce, 16);
    const lp2block = parseInt(lpRewards2.nonce, 16);
    const hidingBlock = parseInt(tradeRewards.nonce, 16);
    const hiding2Block = parseInt(trade2Rewards.nonce, 16);

    const totalPending = lpRewards1.earnings
        .plus(lpRewards2.earnings)
        .plus(keeperRewards.earnings)
        .plus(premineLpRewards.earnings)
        .plus(tradeRewards.earnings)
        .plus(trade2Rewards.earnings)
        .minus(claimedLP1rewards)
        .minus(claimedLP2rewards)
        .minus(claimedTradingRewards)
        .minus(claimedTrading2Rewards)
        .minus(claimedLPPremineRewards);

    const totalLPPreMine = premineLpRewards.earnings.minus(claimedLPPremineRewards);
    const totalHidingGamePendingReward = tradeRewards.earnings.minus(claimedTradingRewards);
    const totalHidingGame2PendingReward = trade2Rewards.earnings.minus(claimedTrading2Rewards);
    const totalLP1PendingReward = lpRewards1.earnings.minus(claimedLP1rewards);
    const totalLP2PendingReward = lpRewards2.earnings.minus(claimedLP2rewards);

    return {
        totalRewardsPending: totalPending,
        totalPreMinePending: totalLPPreMine,
        totalHidingGamePending: totalHidingGamePendingReward,
        totalHidingGame2Pending: totalHidingGame2PendingReward,
        totalLP1Pending: totalLP1PendingReward,
        totalLP2Pending: totalLP2PendingReward,
        lp1Rewards: lpRewards1.earnings,
        lp2Rewards: lpRewards2.earnings,
        keeperRewards: keeperRewards.earnings,
        hidingRewards: tradeRewards.earnings,
        premineBlock,
        lp1block,
        lp2block,
        hidingBlock,
        hiding2Block,
        error,
    };
}

export async function getRookBalance(web3: Web3, network: NetworkID, address: string): Promise<BigNumber> {
    try {
        if (!address) {
            return new BigNumber(0);
        }
        return new BigNumber(
            await web3.eth.call({
                to: contractAddress(network, contractTypeFromToken(Token.ROOK)),
                data: `0x70a08231000000000000000000000000${address.slice(-40)}`,
            }),
        );
    } catch (err) {
        console.log(err);
        return new BigNumber(0);
    }
}

export async function getClaimedROOKBalance(
    web3: Web3,
    network: NetworkID,
    address: string,
    distContract:
        | ContractType.LPDistributor1
        | ContractType.LPDistributor2
        | ContractType.KeeperDistributor
        | ContractType.LPPremineDistributor
        | ContractType.TradeDistributor
        | ContractType.TradeDistributor2,
): Promise<BigNumber | null> {
    try {
        if (!web3 || !network || network === NetworkID.Unsupported || !address) {
            return null;
        }
        const DistributeContract = distibutorContract(web3, network, address, distContract);
        const claimed = await DistributeContract.methods.claimedAmount(address).call();
        return claimed;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function getRookSupply(web3: Web3, network: NetworkID, address: string): Promise<BigNumber> {
    try {
        const rookContract = contract(web3, network, ContractType.ROOK, address);
        const balance = await rookContract.methods.totalSupply().call();

        return balance ? new BigNumber(balance) : ROOK_SUPPLY_HARDCODED;
    } catch (err) {
        console.error(err);
        return new BigNumber(0);
    }
}

export async function getRewards(type: claimerType, address: string, network: NetworkID) {
    try {
        if (!address) {
            return {
                earnings: new BigNumber(0),
                nonce: null,
                signature: null,
                type,
            };
        }
        const base_url = getIndiboURL(type, network);
        const url = `${base_url}${address}`;
        const { data } = await axios.get(url);
        const balance = {
            earnings: data.earnings_to_date ? new BigNumber(data.earnings_to_date) : new BigNumber(0),
            nonce: data.nonce,
            signature: data.signature,
            type,
        };

        return balance;
    } catch (err) {
        console.error(err);
        return {
            earnings: new BigNumber(0),
            nonce: null,
            signature: null,
            type,
        };
    }
}

export async function getAddressRewards(web3: Web3, network: NetworkID, address: string) {
    try {
        if (network === NetworkID.Unsupported) {
            throw new Error('unsupported network');
        }

        //claimed rewards
        const claimedLPRewards1 = await getClaimedROOKBalance(web3, network, address, ContractType.LPDistributor1);
        const claimedLPRewards2 = await getClaimedROOKBalance(web3, network, address, ContractType.LPDistributor2);
        const claimedPreMineLP = await getClaimedROOKBalance(web3, network, address, ContractType.LPPremineDistributor);
        const claimedTradeRewards = await getClaimedROOKBalance(web3, network, address, ContractType.TradeDistributor);
        const claimedTrade2Rewards = await getClaimedROOKBalance(
            web3,
            network,
            address,
            ContractType.TradeDistributor2,
        );

        //total rewards
        const keeperRewards = await getRewards(claimerType.Keeper, address, network);
        const lpRewards1 = await getRewards(claimerType.LiquidityProvider1, address, network);
        const lpRewards2 = await getRewards(claimerType.LiquidityProvider2, address, network);
        const preLpRewards = await getRewards(claimerType.PremineLP, address, network);
        const tradeRewards = await getRewards(claimerType.Trade, address, network);
        const trade2Rewards = await getRewards(claimerType.Trade2, address, network);

        return {
            keeperRewards: keeperRewards,
            lpRewards1: lpRewards1,
            lpRewards2: lpRewards2,
            premineLpRewards: preLpRewards,
            tradeRewards,
            trade2Rewards,
            claimedLP1Rewards: claimedLPRewards1,
            claimedLP2Rewards: claimedLPRewards2,
            claimedPreMineForLP: claimedPreMineLP,
            claimedTradeRewards,
            claimedTrade2Rewards,
        };
    } catch (err) {
        throw new Error(err);
    }
}

function RookBalance() {
    const { connector, library, chainId, account } = useWeb3React();
    const { library: web3library, chainId: networkChainId } = useWeb3React('infura');
    const [rookBalances, setRookBalances] = useState<RookState>(DEFAULT_STATE);
    const [modalState, setModalState] = useState(ClaimRookState.HIDDEN);
    const [amount, setAmount] = useState(null as BigNumber | null);
    const [loading, setLoading] = useState(true);

    async function updateRewards() {
        if (!library && !web3library) {
            return;
        }
        if (!chainId && !networkChainId) {
            return;
        }
        const lib = library ? library : web3library;
        const network = chainId ? networkIdFromChainId(chainId) : networkIdFromChainId(networkChainId);
        const address = account ? account : '';

        // const claimable = await getAddressRewards(lib, network, address);
        // const rookBalance = await getRookBalance(lib, network, address);
        // const rookSupply = await getRookSupply(lib, network, address);
        try {
            const data = await Promise.all([
                getAddressRewards(lib, network, address),
                getRookBalance(lib, network, address),
                getRookSupply(lib, network, address),
                lib.eth.getBlockNumber(),
            ]);
            // const rewardBLockEnd = config.ROOK_REWARD_END_BLOCK - data[3];

            setRookBalances({
                claimable: data[0],
                rookBalance: data[1],
                rookSupply: data[2],
                currentBLock: data[3],
            });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        setLoading(true);
        updateRewards();
        setLoading(false);
        const interval = setInterval(async () => {
            updateRewards();
        }, 30000);
        return () => clearInterval(interval);
    }, [connector, library, web3library, networkChainId, chainId, account, modalState]);

    return { rookBalances, modalState, setModalState, amount, setAmount, loading };
}

function getIndiboURL(type: claimerType, network: NetworkID): string {
    switch (type) {
        case claimerType.Keeper:
            return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ''}KEEPER_REWARD_URL`];
        case claimerType.LiquidityProvider1:
            return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ''}LIQUIDITY_PROVIDER_REWARD_URL`];
        case claimerType.LiquidityProvider2:
            return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ''}LP_Q2_REWARD_URL`];
        case claimerType.PremineLP:
            return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ''}PRE_LIQUIDITY_PROVIDER_REWARD_URL`];
        case claimerType.Trade:
            return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ''}TRADE_REWARD_URL`];
        case claimerType.Trade2:
            return config[`INDIBO_${network === NetworkID.Kovan ? KOVAN : ''}TRADE_REWARD2_URL`];
        default:
            return '';
    }
}

const RookBalances = createContainer(RookBalance);

export default RookBalances;
