import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { components, IndicatorProps, StylesConfig } from 'react-select';
import { ReactComponent as ArrowLeft } from '../styles/images/arrow-left.svg';
import { OptionProps } from 'react-select/src/types';
import { ReactComponent as Arrow } from '../styles/images/arrow.svg';
import { UnderlyingToken, UnderlyingTokens, underlyingTokenSymbol, wrapUnderlyingToken } from '../lib/tokens';
import HistoryTable, { parseTxs } from '../components/HistoryTable';
import ProfitChart from '../components/charts/ProfitChart';
import DepositChart from '../components/charts/DepositChart';
import BorrowChart from '../components/charts/BorrowChart';
import UserActions from '../components/UserActions';
import { useTranslation } from 'react-i18next';
import TokenSVG from '../components/TokenSVG';
import { networkIdFromChainId } from '../lib/network';
import { calculateRequiredBlocksHistory } from '../lib/charts';
import { useWeb3React } from '@web3-react/core';
import { getAPYRequest, tokenID } from '../lib/graphql';
import { calculatePoolAPY, calculateRookAPY } from '../lib/utils';
import BigNumber from 'bignumber.js';
import ExchangeRates from '../containers/exchangeRates';
import {
    useTransactions,
    TransactionType,
    Transaction,
    HistoryResponse,
    useLazyUserTransactions,
    getUserTransactionsVariables,
} from '../containers/subscriptions/transactions';
import { chain } from 'lodash';

interface ITokenAPY {
    pool: string;
    rook: string;
}

function Poolinfo(props: { match: any }): React.ReactElement {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<null | { value: UnderlyingToken }>(null);
    const exchange = ExchangeRates.useContainer();
    const { library, chainId, account } = useWeb3React();
    const { library: networkWeb3, chainId: networkChainId } = useWeb3React('infura');
    const [tokenAPY, setTokenAPY] = useState<ITokenAPY>({ pool: '0.0%', rook: '0.0%' });
    const [tokenExchange, setTokenExchange] = useState<BigNumber>(new BigNumber(0));
    // const { data: transactions, error: transactionsError, loading: transactionsLoading } = useTransactions(
    //     selected?.value || UnderlyingToken.ETH,
    // );
    const [
        getTransactions,
        { data: transactions, loading: transactionsLoading, error: transactionsError },
    ] = useLazyUserTransactions();

    useEffect(() => {
        let interval;
        if (selected && account && chainId) {
            // console.log(getUserTransactionsVariables(account, selected.value, chainId));
            // getTransactions(getUserTransactionsVariables(account, selected.value, chainId));
            getTransactions(getUserTransactionsVariables(account, selected.value, chainId));
            interval = setInterval(() => {
                getTransactions(getUserTransactionsVariables(account, selected.value, chainId));
            }, 10000);
        }
        return () => interval && clearInterval(interval);
    }, [selected, library, chainId, account]);

    useEffect(() => {
        if (selected) {
            setTokenExchange(exchange[UnderlyingToken[selected?.value]]);
        }
    }, [selected, exchange]);

    useEffect(() => {
        async function getAPYs() {
            if ((!library && !networkWeb3) || (!chainId && !networkChainId) || !selected) {
                return;
            }
            const web3 = !!library ? library : networkWeb3;
            const chain = library && chainId ? chainId : networkChainId;
            const net = networkIdFromChainId(chain);
            const latestBlock = await web3.eth.getBlockNumber();
            const blockWeekAgo = calculateRequiredBlocksHistory(latestBlock, 7, net)[0];
            const token = wrapUnderlyingToken(selected.value);
            const { data, error } = await getAPYRequest(blockWeekAgo, tokenID(token, net), net);
            if (error) {
                // console.error(error);
                return;
            }
            const tokenSupply = new BigNumber(data.last[0].deposited).minus(new BigNumber(data.last[0].withdrawn));

            setTokenAPY({
                pool: calculatePoolAPY(data).percent,
                rook: calculateRookAPY(
                    net,
                    tokenSupply,
                    tokenExchange,
                    exchange['ROOK'],
                    wrapUnderlyingToken(selected.value),
                ).percent,
            });
        }
        getAPYs();
    }, [library, chainId, networkWeb3, networkChainId, tokenExchange, exchange]);

    useEffect(() => {
        let token = underlyingTokenOptions().find((t) => UnderlyingToken[t.value] === props.match.params.id);
        token = token ? token : underlyingTokenOptions()[0];
        if (selected?.value !== token.value) {
            setSelected(token);
        }
    }, [props]);

    const history = useHistory();

    return (
        <div className="page-content">
            <div className="container">
                <div className="pool-control">
                    <div className="back-btn" onClick={() => history.push('/')}>
                        <ArrowLeft />
                        <span>{t('pool-info.back-btn').toUpperCase()}</span>
                    </div>
                    <Select
                        className="select"
                        components={{ DropdownIndicator }}
                        value={selected}
                        options={underlyingTokenOptions()}
                        clearIndicator={true}
                        isSearchable={false}
                        isOptionDisabled={(option: any) => option.disabled}
                        styles={customStyles}
                        onChange={(v) => setSelected(v)}
                    />
                </div>
                {/* <div className="container-row">
                    <div className="yield-container">
                        <div className="yield-col">
                            <h3>{t('pool-info.annual-percentage-yield').toUpperCase()}</h3>
                            <span>{`${tokenAPY.rook}`}</span>
                        </div>
                    </div>
                </div> */}
                <div className="container-row">
                    <div className="graph-wrapper">
                        <div className="graph-col">
                            <DepositChart underlyingToken={selected} />
                        </div>
                        {/* <div className="graph-col">
                            <ProfitChart underlyingToken={selected} />
                        </div> */}
                        <div className="graph-col">
                            <BorrowChart underlyingToken={selected} />
                        </div>
                    </div>
                </div>
                <div className="container-row">
                    {selected && (
                        <div className="user-actions-wrapper">
                            <UserActions underlyingToken={selected.value} transactions={transactions} />
                        </div>
                    )}
                </div>
                <div className="container-row">
                    {selected && (
                        <div className="history-wrapper">
                            <HistoryTable
                                underlyingToken={selected.value}
                                transactions={transactions}
                                loading={transactionsLoading}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

// Custom options/components/styles for react-select.

function underlyingTokenOptions() {
    return [
        ...UnderlyingTokens.map((uToken) => ({
            value: uToken,
            label: dropDownlabel(uToken),
            disabled: false,
            icon: <TokenSVG underlyingToken={uToken} />,
        })),
        // { value: "", label: "More coming soon!", disabled: true }
    ];
}

const dropDownlabel = (t: UnderlyingToken) => {
    return (
        <div className="label-drob-list">
            {<TokenSVG underlyingToken={t} />}
            <span>{underlyingTokenSymbol(t)}</span>
        </div>
    );
};

const DropdownIndicator = (props: IndicatorProps<any, boolean>) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Arrow />
            </components.DropdownIndicator>
        )
    );
};

const customStyles: StylesConfig<any, boolean> = {
    option: (provided: React.CSSProperties, props: any) => ({
        display: 'flex',
        alignItems: 'center',
        color: '#16212b',
        cursor: 'pointer',
        backgroundColor: props.isFocused ? '#f4f6f7' : 'white',
        height: '35px',
        padding: '0 15px',
    }),
    control: () => ({
        display: 'flex',
        backgroundColor: 'white',
        cursor: 'pointer',
        fontSize: '14px',
        height: '35px',
        width: '160px',
        borderRadius: '5px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
    }),
    singleValue: (provided: React.CSSProperties) => ({
        ...provided,
        color: '#16212b',
        outline: 'none',
    }),
    menu: (provided: React.CSSProperties) => ({
        ...provided,
        fontSize: '14px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
    }),
    valueContainer: (provided: React.CSSProperties) => ({
        ...provided,
        position: undefined,
        padding: '0 15px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: () => ({
        display: 'flex',
        alignItems: 'center',
        marginRight: '7px',
    }),
};

export default Poolinfo;
