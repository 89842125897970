import React, { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { AbstractConnector } from '@web3-react/abstract-connector';
import wallet, { WalletModalState, Web3ReactManagerReturn } from '../../containers/wallet';
import { ReactComponent as CloseIcon } from '../../styles/images/close-icon.svg';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';

import { EVENT_CATEGORIES, ACTIONS } from '../../lib/analytics';
import GA from '../../containers/analytics';

import {
    ConnectorNames,
    connectorsByName,
    injectedConnector,
    walletconnect,
    walletlink,
    fortmatic,
    portis,
} from '../../lib/connectors';
import { usePrevious } from '../../hooks';
import { isMobile } from 'react-device-detect';

function sliceAccount(account) {
    return `${account.slice(0, 6)}...${account.slice(-4)}`;
}

export default function WalletModal() {
    const {
        connector,
        library,
        chainId,
        account,
        deactivate,
        active,
        activate,
        error,
    }: Web3ReactManagerReturn = useWeb3React();
    const { triedEager, modalState, setModalState, modalOpen, setModalOpen } = wallet.useContainer();
    const [showWalletOptions, setShowWalletOptions] = useState(false);
    const [activatingConnector, setActivatingConnector] = React.useState<any>();
    const [pendingWallet, setPendingWallet] = useState(undefined);
    const [pendingError, setPendingError] = useState(false);
    const activePrevious = usePrevious(active);
    const connectorPrevious = usePrevious(connector);
    const { sendEvent } = GA.useContainer();
    const { t } = useTranslation();

    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // change modal state to show account info if a connection is successful
    useEffect(() => {
        if (modalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
            setModalState(WalletModalState.ACCOUNT);
            setShowWalletOptions(false);
        }
    }, [setModalState, active, error, connector, modalOpen, activePrevious, connectorPrevious]);

    //set modal to show wallet options if a wallet is not connected
    useEffect(() => {
        if (!account && !pendingWallet && !active && modalOpen) {
            setShowWalletOptions(true);
        }
    }, [modalOpen, account, pendingWallet, active, error]);

    async function deactivateCurrentConnector() {
        if (connector && active) {
            switch (connector) {
                case walletlink:
                case walletconnect:
                case fortmatic:
                case portis:
                    await connector.close();
                    deactivate();
                    return;
                default:
                    deactivate();
                    return;
            }
        }
    }

    const handleCopyAddress = () => {
        if (account) {
            navigator.clipboard?.writeText(account);
        }
    };

    const connectorName = (connector: AbstractConnector) => {
        switch (connector) {
            case injectedConnector:
                return ConnectorNames.Injected;
            case walletconnect:
                return ConnectorNames.WalletConnect;
            case walletlink:
                return ConnectorNames.WalletLink;
            case portis:
                return ConnectorNames.Portis;
            case fortmatic:
                return ConnectorNames.Fortmatic;
            default:
                return 'Undefined Wallet';
        }
    };

    function connectedWallet() {
        if (account && active) {
            return (
                <div className="wallet-info">
                    <div className="account-control">
                        <span className="control-title">{t('wallet-modal.account')}</span>
                        <div className="address-control">
                            <span className="address">{sliceAccount(account)}</span>
                            <span className="address-ctr-btn" onClick={handleCopyAddress}>
                                {t('wallet-modal.copy-btn')}
                            </span>
                            {!showWalletOptions && (
                                <span className="address-ctr-btn" onClick={() => setShowWalletOptions(true)}>
                                    {t('wallet-modal.change-btn')}
                                </span>
                            )}
                            {connector && connector !== injectedConnector && (
                                <span
                                    className="address-ctr-btn"
                                    onClick={async () => {
                                        await deactivateCurrentConnector();
                                        setShowWalletOptions(true);
                                    }}
                                >
                                    {t('wallet-modal.disconnect-btn')}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>{connector && <span>Connected with {connectorName(connector)}</span>}</div>
                </div>
            );
        }

        return null;
    }

    const onConnectWalletError = () => {
        setPendingError(false);
        setActivatingConnector(undefined);
        setModalState(WalletModalState.ACCOUNT);
        setShowWalletOptions(true);
    };

    const connectWallet = async (connector) => {
        setPendingWallet(connector);
        setModalState(WalletModalState.PENDING);
        activate(connector, undefined, true)
            .then(() => {
                setModalState(WalletModalState.ACCOUNT);
                setPendingWallet(undefined);
            })
            .catch((e) => {
                console.log(e);
                setPendingError(true);
            });
    };

    const walletOptions = useCallback(() => {
        if (showWalletOptions) {
            return (
                <div className="wallet-options">
                    {Object.keys(connectorsByName).map((name) => {
                        const currentConnector = connectorsByName[name];

                        //skip network connector
                        if (name === ConnectorNames.Network) {
                            return null;
                        }

                        //skip injected if not installed
                        if (name === ConnectorNames.Injected && window.ethereum === undefined) {
                            return null;
                        }

                        // do not show current connector
                        if (connector === connectorsByName[name]) {
                            return null;
                        }

                        //skip portis on mobile
                        if (isMobile && name === ConnectorNames.Portis) {
                            return null;
                        }

                        const connected = currentConnector === connector;
                        const disabled = !triedEager || connected || !!error;

                        return (
                            <button
                                className="wallet-option-btn"
                                disabled={disabled}
                                key={name}
                                onClick={() => {
                                    setActivatingConnector(currentConnector);
                                    connectWallet(connectorsByName[name]);
                                    sendEvent({
                                        category: EVENT_CATEGORIES.WALLET,
                                        action: ACTIONS.WALLET_CHANGED,
                                        label: name,
                                    });
                                }}
                            >
                                {name}
                            </button>
                        );
                    })}
                </div>
            );
        }
        return null;
    }, [connector, active, showWalletOptions, library, error]);

    function pendingView() {
        return (
            <div className="connecting-info">
                {pendingError ? (
                    <div className="connecting-info-cont">
                        <span className="connecting-err-msg">{t('wallet-modal.err')}</span>
                        <span className="connecting-err-btn" onClick={onConnectWalletError}>
                            {t('wallet-modal.try-btn')}
                        </span>
                    </div>
                ) : (
                    <div className="connecting-info-cont">
                        <ClipLoader size={30} color="#09Af73" loading={true} />
                        <span>{t('wallet-modal.connecting')}</span>
                    </div>
                )}
            </div>
        );
    }

    if (!modalOpen) return null;

    return (
        <div className="popup">
            <div className="inner wallet">
                <span className="close" onClick={() => setModalOpen(false)}>
                    <CloseIcon />
                </span>
                <h2>{t('wallet-modal.wallet-header')}</h2>
                {connectedWallet()}
                {modalState === WalletModalState.PENDING ? pendingView() : walletOptions()}
            </div>
        </div>
    );
}
