import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { getTokenPrices } from '../lib/coingecko';
import BigNumber from 'bignumber.js';
import { Token } from '../lib/tokens';

const EXCHANGE_DEFAULT = {
    ETH: new BigNumber(0),
    WETH: new BigNumber(0),
    DAI: new BigNumber(0),
    USDC: new BigNumber(0),
    BTC: new BigNumber(0),
    ROOK: new BigNumber(0),
};

const TOKENS_WITH_PRICE = [Token.BTC, Token.DAI, Token.USDC, Token.ETH, Token.WETH, Token.ROOK];

function useRates() {
    const [rates, setRates] = useState(EXCHANGE_DEFAULT);
    const fetchCoinPrices = async () => {
        try {
            const exchangePrices = await getTokenPrices(TOKENS_WITH_PRICE);

            setRates(exchangePrices);
        } catch (err) {
            console.log('Unable to fetch exchange rates from Coingecko');
        }
    };

    // on initial mount
    useEffect(() => {
        fetchCoinPrices();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            fetchCoinPrices();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    return rates;
}

const ExchangeRates = createContainer(useRates);

export default ExchangeRates;
