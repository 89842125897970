import axios from 'axios';
import { Token } from './tokens';
import BigNumber from 'bignumber.js';
export type IExchangePrice<T> = { [key in keyof typeof Token]: T };
export enum GeckoId {
    ETH = 'ethereum',
    WETH = 'weth',
    USDC = 'usd-coin',
    DAI = 'dai',
    renBTC = 'renbtc',
    USD = 'usd',
    ROOK = 'rook',
}
const COINGECKO_PRICES_URL = 'https://api.coingecko.com/api/v3/simple/price?ids=';

export function tokenToId(token: Token): GeckoId {
    switch (token) {
        case Token.ETH:
            return GeckoId.ETH;
        case Token.WETH:
            return GeckoId.WETH;
        case Token.USDC:
            return GeckoId.USDC;
        case Token.DAI:
            return GeckoId.DAI;
        case Token.BTC:
            return GeckoId.renBTC;
        case Token.ROOK:
            return GeckoId.ROOK;
        default:
            throw new TypeError('non-exhaustive pattern');
    }
}

export function geckoIdToToken(id: GeckoId | string): Token {
    switch (id) {
        case GeckoId.ETH:
            return Token.ETH;
        case GeckoId.WETH:
            return Token.WETH;
        case GeckoId.USDC:
            return Token.USDC;
        case GeckoId.DAI:
            return Token.DAI;
        case GeckoId.renBTC:
            return Token.BTC;
        case GeckoId.ROOK:
            return Token.ROOK;
        default:
            throw new TypeError('non-exhaustive pattern');
    }
}

export async function getTokenPrices(ids: Array<Token>): Promise<IExchangePrice<BigNumber>> {
    const idsList = ids.map((id) => tokenToId(id));
    const result = await axios.get(`${COINGECKO_PRICES_URL}${idsList.join(',')}&vs_currencies=${GeckoId.USD}`);
    const exchangePrices = Object.keys(result.data).reduce((obj, key) => {
        const currency = Token[geckoIdToToken(key)];
        return {
            ...obj,
            [currency]: new BigNumber(result.data[key]['usd']),
        };
    }, {} as IExchangePrice<BigNumber>);

    return exchangePrices;
}
