import React, { useState, useEffect } from 'react';
import { useKTokenSupply, usePoolAssets } from '../../containers/subscriptions/pool';
import { useBalance } from '../../containers/subscriptions/user';
import Withdrawer, { WithdrawerState } from '../../containers/withdrawer';
import { useWeb3React } from '@web3-react/core';
import Balances from '../../containers/balances';
import BarLoader from 'react-spinners/BarLoader';
import {
    UnderlyingToken,
    underlyingTokenToKToken,
    kTokenSymbol,
    underlyingTokenSymbol,
    wrapKToken,
    tokenDecimals,
    wrapUnderlyingToken,
} from '../../lib/tokens';
import { ReactComponent as CloseIcon } from '../../styles/images/close-icon.svg';
import BigNumber from 'bignumber.js';
import { contract, contractAddress, ContractType, contractTypeFromToken } from '../../lib/contracts';
import { useTranslation } from 'react-i18next';
import { networkIdFromChainId } from '../../lib/network';

import { EVENT_CATEGORIES, ACTIONS } from '../../lib/analytics';
import GA from '../../containers/analytics';

function useWithdrawModal(underlyingToken: UnderlyingToken) {
    // State variables use for inputting the withdraw amount. We need an
    // explicit state variable because we need to do bignumber/decimal/string
    // conversions, and haveing an explicit state variable makes this much
    // easier.
    const [input, setInput] = useState('');

    // The Wallet is needed so that we can sign and submit transactions to
    // Ethereum.
    const wallet = useWeb3React();

    // analytics
    const { sendEvent } = GA.useContainer();

    // Balances is needed so that we know the maximum amount that can be
    // deposited. There is no point letting the user attempt to deposit more
    // than the maximum, since it would just revert.
    const balances = Balances.useContainer();

    const { balance } = useBalance(underlyingToken);
    const { totalSupply } = useKTokenSupply(underlyingToken);
    const { poolAssets } = usePoolAssets(underlyingToken);

    const { t } = useTranslation();

    // The withdrawer is needed so that we can know the current state of the
    // withdrawer.
    const withdrawer = Withdrawer.useContainer();

    //update input on modal state change
    useEffect(() => {
        setInput('');
    }, [withdrawer.state]);

    async function withdraw() {
        withdrawer.setState(WithdrawerState.WAIT_FOR_WITHDRAW);
        if (!wallet.library || !wallet.chainId || !wallet.account) {
            return;
        }
        const web3 = wallet.library;
        const networkID = networkIdFromChainId(wallet.chainId);
        const liquidityPoolContract = contract(web3, networkID, ContractType.LiquidityPool, wallet.account);

        // Get the kToken balance of the current wallet.
        const kTokenBalance = new BigNumber(balance);
        // Get the total supply of the kToken. This is needed for computing the max
        // withdrawable amount denominated in the underlying token.
        const kTokenTotalSupply = totalSupply;

        // Get the kToken associated with the underlying token.
        const kToken = underlyingTokenToKToken(underlyingToken);

        // Get the borrowable balance of the underlying token (this is the
        // underlying token balance of the liquidity pool). This is needed for
        // computing the max withdrawable amount denominated in the underlying
        // token.
        const underlyingTokenBorrowableBalance = poolAssets;

        // We are about to use these quite a few times, so it is nicer to define a
        // constant for it.
        const zero = new BigNumber(0);
        const one = new BigNumber(1);

        // Compute the current amount to be withdrawn denominated in the kToken.
        const kTokenWithdrawAmount = underlyingAmountToKAmount(
            withdrawer.amount || zero,
            kTokenTotalSupply,
            underlyingTokenBorrowableBalance,
        );
        // const underlyingTokenAddress = contractAddress(networkID, contractTypeFromToken(wrapUnderlyingToken(underlyingToken)));
        const kTokenAddress = contractAddress(networkID, contractTypeFromToken(wrapKToken(kToken)));
        try {
            await liquidityPoolContract.methods
                .withdraw(wallet.account, kTokenAddress, kTokenWithdrawAmount.toFixed(0))
                .send({ from: wallet.account })
                .on('transactionHash', (hash) => {
                    // console.log('Tx hash came => ', hash);
                })
                .on('receipt', async (receipt) => {
                    // console.log('Tx receipt came => ', receipt);
                    withdrawer.setState(WithdrawerState.SUCCESS);
                })
                .on('error', (error, receipt) => {
                    withdrawer.setState(WithdrawerState.CANCEL_WITHDRAW);
                    return;
                });
        } catch (err) {
            console.error(`cannot withdraw: ${err}`);
            withdrawer.setState(WithdrawerState.CANCEL_WITHDRAW);
        }
    }

    return {
        input,
        setInput,
        ...wallet,
        ...balances,
        ...withdrawer,
        balance,
        totalSupply,
        poolAssets,
        t,
        withdraw,
        sendEvent,
    };
}

function Hidden(underlyingToken: UnderlyingToken) {
    // It is important that this component is explicitly rendered when the modal
    // is hidden, otherwise the order of the "useState" calls will change when
    // the model opens/closes, and this will cause all sorts of undefined
    // behaviour!
    const _ = useWithdrawModal(underlyingToken);
    return null;
}

function WaitForInput(underlyingToken: UnderlyingToken) {
    const withdrawModal = useWithdrawModal(underlyingToken);

    // The withdrawer is in the wrong state, and this component should not be
    // rendered. This is a defensive check, and should never actually happen.
    if (withdrawModal.state !== WithdrawerState.WAIT_FOR_INPUT) {
        return null;
    }

    // Get the kToken associated with the underlying token.
    const kToken = underlyingTokenToKToken(underlyingToken);

    // Get the kToken balance of the current wallet.
    const kTokenBalance = new BigNumber(withdrawModal.balance);
    // Get the total supply of the kToken. This is needed for computing the max
    // withdrawable amount denominated in the underlying token.
    const kTokenTotalSupply = withdrawModal.totalSupply;

    // Get the borrowable balance of the underlying token (this is the
    // underlying token balance of the liquidity pool). This is needed for
    // computing the max withdrawable amount denominated in the underlying
    // token.
    const underlyingTokenBorrowableBalance = withdrawModal.poolAssets;

    // We are about to use these quite a few times, so it is nicer to define a
    // constant for it.
    const zero = new BigNumber(0);
    const one = new BigNumber(1);

    // Compute the current amount to be withdrawn denominated in the kToken.
    const kTokenWithdrawAmount = underlyingAmountToKAmount(
        withdrawModal.amount || zero,
        kTokenTotalSupply,
        underlyingTokenBorrowableBalance,
    );

    // If the input entered is empty, or more than the maximum withdrawable
    // amount, then disable the withdraw button. There is no point letting the
    // user attempt a transaction that we know will revert.
    const disabled =
        !withdrawModal.input || parseFloat(withdrawModal.input) <= 0 || kTokenWithdrawAmount.gt(kTokenBalance);

    // Decimal scaling is used to scale between the string based amount (input
    // by the user) and the shared state value (a big number with no decimals
    // that will be handed to the contracts).
    const decimalScaling = new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(underlyingToken)));

    const underlyingTokenMaxWithdrawable = kAmountToUnderlyingAmount(
        kTokenBalance,
        kTokenTotalSupply,
        underlyingTokenBorrowableBalance,
    );
    async function checkApproval() {
        if (!withdrawModal.library || !withdrawModal.chainId || !withdrawModal.account) {
            return;
        }
        const web3 = withdrawModal.library;
        const networkID = networkIdFromChainId(withdrawModal.chainId);
        const kTokenContract = contract(
            web3,
            networkID,
            contractTypeFromToken(wrapKToken(kToken)),
            withdrawModal.account,
        );
        const liquidityPoolAddress = contractAddress(networkID, ContractType.LiquidityPool);
        const allowance = await kTokenContract.methods.allowance(withdrawModal.account, liquidityPoolAddress).call();
        const widthdrawalAmount = kTokenWithdrawAmount;

        if (new BigNumber(allowance).gte(widthdrawalAmount)) {
            //'sufficient allowance'
            await withdrawModal.withdraw();
        } else {
            //promt message to set allowance to unlimited
            withdrawModal.setState(WithdrawerState.SET_UNLIMITED_APPROVAL);
            return;
        }
    }

    return (
        <div className="popup funds">
            <div className="inner">
                <span className="close" onClick={() => withdrawModal.setState(WithdrawerState.HIDDEN)}>
                    <CloseIcon />
                </span>
                <h2>{withdrawModal.t('withdraw-modal.withdraw-header')}</h2>
                <p>
                    {withdrawModal.t('withdraw-modal.input-popup', {
                        underlyingTokenSymbol: underlyingTokenSymbol(underlyingToken),
                        ktoken: kTokenSymbol(kToken),
                    })}
                </p>
                <div className="input">
                    <input
                        className="amount"
                        name="amount"
                        placeholder={withdrawModal.t('withdraw-modal.input-placeholder', {
                            token: underlyingTokenSymbol(underlyingToken),
                        })}
                        value={withdrawModal.input}
                        onChange={(evt) => {
                            // Remove spaces.
                            let v = evt.currentTarget.value.replace(/\s/g, '');
                            // Remove second occurrences of periods.
                            v = v
                                .split('')
                                .filter((x, n, self) => self.indexOf(x) === n || x !== '.')
                                .join('');
                            // Remove non-digits (unless it is a period).
                            v = v.replace(/[^\d\.]/g, '');
                            // Set the withdraw amount as a big number. (This is
                            // the shared state value.)
                            withdrawModal.setAmount(new BigNumber(v).multipliedBy(decimalScaling));
                            // Set the amount as a string so that input is not
                            // messed up when typing "." characters.
                            withdrawModal.setInput(v);
                        }}
                    />
                    <span
                        onClick={() => {
                            // Set the display withdraw amount as a big number. (This is the
                            // shared state value.)
                            withdrawModal.setAmount(underlyingTokenMaxWithdrawable);
                            // Set the amount as a string so that input is not
                            // messed up when typing "." characters.
                            withdrawModal.setInput(underlyingTokenMaxWithdrawable.dividedBy(decimalScaling).toFixed());
                        }}
                    >
                        {withdrawModal.t('withdraw-modal.max')}
                    </span>
                </div>
                <div className="balance-row">
                    <div className="balance-cont">
                        <span>Max:</span>
                        <span>
                            {underlyingTokenMaxWithdrawable
                                ? underlyingTokenMaxWithdrawable.dividedBy(decimalScaling).toFixed(2)
                                : 0}
                        </span>
                    </div>
                </div>
                <div className="buttons">
                    <div
                        className={`modal-button ${disabled ? 'disabled' : ''}`}
                        onClick={async () => {
                            // Do nothing if the button is disabled.
                            if (disabled) {
                                return;
                            }

                            // send GA event
                            withdrawModal.sendEvent({
                                category: EVENT_CATEGORIES.POOL_BALANCE,
                                action: ACTIONS.WITHDRAW,
                                label: underlyingTokenSymbol(underlyingToken),
                                value: withdrawModal.amount?.dividedBy(decimalScaling).toNumber(),
                            });

                            // Begin with an approve.
                            await checkApproval();
                        }}
                    >
                        {withdrawModal.t('withdraw-modal.withdraw-btn')}
                    </div>
                    <div className="modal-button alt" onClick={() => withdrawModal.setState(WithdrawerState.HIDDEN)}>
                        {withdrawModal.t('withdraw-modal.cancel-btn')}
                    </div>
                </div>
            </div>
        </div>
    );
}

function SetUnlimitedApproval(underlyingToken: UnderlyingToken) {
    const withdrawModal = useWithdrawModal(underlyingToken);
    // Get the kToken associated with the underlying token.
    const kToken = underlyingTokenToKToken(underlyingToken);

    async function withdrawtWithApproval(unlimited = false) {
        withdrawModal.setState(WithdrawerState.WAIT_FOR_APPROVE);

        if (!withdrawModal.library || !withdrawModal.chainId || !withdrawModal.account) {
            return;
        }
        const web3 = withdrawModal.library;
        const networkID = networkIdFromChainId(withdrawModal.chainId);
        const liquidityPoolAddress = contractAddress(networkID, ContractType.LiquidityPool);
        const kTokenContract = contract(
            withdrawModal.library,
            networkID,
            contractTypeFromToken(wrapKToken(kToken)),
            withdrawModal.account,
        );

        const amount = withdrawModal.amount ? new BigNumber(withdrawModal.amount) : new BigNumber(0);

        const maxAllowance = unlimited ? new BigNumber(2).pow(256).minus(1) : amount;

        try {
            if (!maxAllowance || maxAllowance.isNaN()) {
                withdrawModal.setState(WithdrawerState.CANCEL_APPROVE);
                return;
            }
            await kTokenContract.methods
                .approve(liquidityPoolAddress, maxAllowance.toFixed(0))
                .send({ from: withdrawModal.account })
                .on('transactionHash', (hash) => {
                    // console.log('Tx hash came => ', hash);
                })
                .on('receipt', async (receipt) => {
                    // console.log('Tx receipt came => ', receipt);
                    await withdrawModal.withdraw();
                })
                .on('error', (error) => {
                    withdrawModal.setState(WithdrawerState.CANCEL_APPROVE);
                    return;
                });
        } catch (err) {
            console.error(`cannot approve: ${err}`);
            withdrawModal.setState(WithdrawerState.CANCEL_APPROVE);
            return;
        }
    }

    return (
        <div className="popup funds">
            <div className="inner">
                <span className="close" onClick={() => withdrawModal.setState(WithdrawerState.HIDDEN)}>
                    <CloseIcon />
                </span>
                <h2>{withdrawModal.t('deposit-modal.unlimited-approval-header')}</h2>
                <p>
                    {withdrawModal.t('deposit-modal.unlimited-approval-popup', {
                        underlyingTokenSymbol: kTokenSymbol(kToken),
                    })}
                </p>
                <div className="buttons">
                    <div
                        className={`modal-button`}
                        onClick={async () => {
                            await withdrawtWithApproval(true);
                        }}
                    >
                        {withdrawModal.t('deposit-modal.approve-yes-btn')}
                    </div>
                    <div className="modal-button" onClick={async () => await withdrawtWithApproval()}>
                        {withdrawModal.t('deposit-modal.approve-no-btn')}
                    </div>
                </div>
            </div>
        </div>
    );
}

function WaitForTx(underlyingToken: UnderlyingToken, message: string) {
    const withdrawModal = useWithdrawModal(underlyingToken);

    // Decimal scaling is used to scale between the string based amount (input
    // by the user) and the shared state value (a big number with no decimals
    // that will be handed to the contracts).
    const decimalScaling = new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(underlyingToken)));

    return (
        <div className="popup funds">
            <div className="inner">
                <h2>{withdrawModal.t('withdraw-modal.withdraw-header')}</h2>
                <p>{message}</p>
                <div className="bar-spinner-wrapper">
                    <BarLoader color="#09Af73" loading={true} />
                </div>
                <div className="buttons">
                    <div className="modal-button cancel" onClick={() => withdrawModal.setState(WithdrawerState.HIDDEN)}>
                        {withdrawModal.t('withdraw-modal.cancel-btn')}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Success(underlyingToken: UnderlyingToken) {
    const withdrawModal = useWithdrawModal(underlyingToken);

    // Decimal scaling is used to scale between the string based amount (input
    // by the user) and the shared state value (a big number with no decimals
    // that will be handed to the contracts).
    const decimalScaling = new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(underlyingToken)));

    return (
        <div className="popup funds">
            <div className="inner">
                <h2>{withdrawModal.t('withdraw-modal.withdraw-header')}</h2>
                <p>
                    {withdrawModal.t('withdraw-modal.successful-withdrawal', {
                        amount: withdrawModal.amount?.dividedBy(decimalScaling).toFixed(),
                        token: underlyingTokenSymbol(underlyingToken),
                    })}
                </p>
                <div className="buttons">
                    <div
                        className="modal-button"
                        onClick={() => {
                            withdrawModal.setInput('');
                            withdrawModal.setAmount(null);
                            withdrawModal.setState(WithdrawerState.HIDDEN);
                        }}
                    >
                        {withdrawModal.t('withdraw-modal.ok-btn')}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Message(underlyingToken: UnderlyingToken, message: string) {
    const withdrawModal = useWithdrawModal(underlyingToken);
    return (
        <div className="popup funds">
            <div className="inner">
                <h2>{withdrawModal.t('withdraw-modal.withdraw-header')}</h2>
                <p>{message}</p>
                <div className="buttons">
                    <div className="modal-button" onClick={() => withdrawModal.setState(WithdrawerState.HIDDEN)}>
                        {withdrawModal.t('withdraw-modal.ok-btn')}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface Props {
    underlyingToken: UnderlyingToken;
}

function WithdrawModal({ underlyingToken }: Props) {
    const withdrawModal = useWithdrawModal(underlyingToken);

    // Decimal scaling is used to scale between the string based amount (input
    // by the user) and the shared state value (a big number with no decimals
    // that will be handed to the contracts).
    const decimalScaling = new BigNumber(10).pow(tokenDecimals(wrapUnderlyingToken(underlyingToken)));

    switch (withdrawModal.state) {
        case WithdrawerState.HIDDEN:
            return Hidden(underlyingToken);
        case WithdrawerState.WAIT_FOR_INPUT:
            return WaitForInput(underlyingToken);
        case WithdrawerState.SET_UNLIMITED_APPROVAL:
            return SetUnlimitedApproval(underlyingToken);
        case WithdrawerState.WAIT_FOR_APPROVE:
            return WaitForTx(
                underlyingToken,
                withdrawModal.t('withdraw-modal.withdraw-wait-approval', {
                    token: kTokenSymbol(underlyingTokenToKToken(underlyingToken)),
                }),
            );
        case WithdrawerState.WAIT_FOR_WITHDRAW:
            return WaitForTx(
                underlyingToken,
                withdrawModal.t('withdraw-modal.withdraw-wait', {
                    amount: withdrawModal.amount?.dividedBy(decimalScaling).toFixed(),
                    token: underlyingTokenSymbol(underlyingToken),
                }),
            );
        case WithdrawerState.SUCCESS:
            return Success(underlyingToken);
        case WithdrawerState.ERROR:
            return Message(underlyingToken, withdrawModal.t('withdraw-modal.withdraw-error'));
        case WithdrawerState.CANCEL_APPROVE:
            return Message(underlyingToken, withdrawModal.t('withdraw-modal.approval-cancel'));
        case WithdrawerState.CANCEL_WITHDRAW:
            return Message(underlyingToken, withdrawModal.t('withdraw-modal.withdraw-cancel'));
        default:
            throw new TypeError(`non-exhaustive pattern: ${withdrawModal.state}`);
    }
}

/**
 * Convert an amount of underlying tokens to an amount of kTokens. The
 * conversion is done in the context of withdrawing underlying tokens from the
 * liquidity pool. This function returns the number of kTokens that must be
 * burned in order to withdraw the given number of underlying tokens.
 *
 * @param underlyingAmount The amount of underlying tokens that we want to
 * withdraw.
 * @param kTokenTotalSupply The total supply of kTokens.
 * @param underlyingTokenBorrowableBalance The maximum amount of underlying
 * tokens that can be borrowed from the liquidity pool.
 */
function underlyingAmountToKAmount(
    underlyingAmount: BigNumber,
    kTokenTotalSupply: BigNumber,
    underlyingTokenBorrowableBalance: BigNumber,
) {
    return underlyingAmount
        .multipliedBy(kTokenTotalSupply)
        .dividedBy(underlyingTokenBorrowableBalance)
        .integerValue(BigNumber.ROUND_UP);
}

/**
 * Convert an amount of kTokens to an amount of underlying tokens. The
 * conversion is done in the context of withdrawing underlying tokens from the
 * liquidity pool. This function returns the number of underlying tokens that
 * can be withdrawn by burning the given number of kTokens.
 *
 * @param kAmount The amount of kTokens that will be burned.
 * @param kTokenTotalSupply The total supply of kTokens.
 * @param underlyingTokenBorrowableBalance The maximum amount of underlying
 * tokens that can be borrowed from the liquidity pool.
 */
function kAmountToUnderlyingAmount(
    kAmount: BigNumber,
    kTokenTotalSupply: BigNumber,
    underlyingTokenBorrowableBalance: BigNumber,
) {
    return kAmount
        .multipliedBy(underlyingTokenBorrowableBalance)
        .dividedBy(kTokenTotalSupply)
        .integerValue(BigNumber.ROUND_DOWN);
}

export default WithdrawModal;
