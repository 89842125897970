/**
 * Enumerates all of the Ethereum networks supported by this frontend. In
 * general, the network active in the current web3 wallet should be the network
 * used throughout the frontend.
 */
export enum NetworkID {
    Mainnet = 'mainnet',
    Kovan = 'kovan',
    Ropsten = 'ropsten',
    Unsupported = 'unsupported',
}

export function networkIdFromChainId(network: number | undefined): NetworkID {
    switch (network) {
        case 1:
            return NetworkID.Mainnet;
        case 3:
            return NetworkID.Ropsten;
        case 42:
            return NetworkID.Kovan;
        default:
            return NetworkID.Unsupported;
    }
}

export function networkIDFromString(network: string): NetworkID {
    switch (network) {
        case 'mainnet':
        case 'main':
            return NetworkID.Mainnet;
        case 'testnet':
        case 'kovan':
            return NetworkID.Kovan;
        case 'ropsten':
            return NetworkID.Ropsten;
        default:
            return NetworkID.Unsupported;
    }
}
