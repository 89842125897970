import React from 'react';
import { UnderlyingToken } from '../lib/tokens';
import { ReactComponent as UsdcIcon } from '../styles/images/usdc.svg';
import { ReactComponent as EthIcon } from '../styles/images/eth-icon.svg';
import { ReactComponent as DaiIcon } from '../styles/images/dai.svg';
import { ReactComponent as WethIcon } from '../styles/images/weth.svg';
import { ReactComponent as BtcIcon } from '../styles/images/renBTC-icon.svg';

const TOKEN_ICONS = {
    [UnderlyingToken.ETH]: <EthIcon />,
    [UnderlyingToken.WETH]: <WethIcon />,
    [UnderlyingToken.USDC]: <UsdcIcon />,
    [UnderlyingToken.DAI]: <DaiIcon />,
    [UnderlyingToken.BTC]: <BtcIcon />
};

export default function tokenSVG({ underlyingToken }: { underlyingToken: UnderlyingToken }) {
    return TOKEN_ICONS[underlyingToken];
}
