import { useEffect, useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { Token, Tokens } from '../lib/tokens';
import { contractAddress, contractTypeFromToken } from '../lib/contracts';
import { NetworkID, networkIdFromChainId } from '../lib/network';
import { useWeb3React } from '@web3-react/core';

async function balanceOf(web3: Web3, network: NetworkID, token: Token, address: string) {
    try {
        if (token === Token.ETH) {
            // console.log("eth balance update")
            return new BigNumber(await web3.eth.getBalance(address));
        } else {
            // console.log("erc-20 balance update")
            const bal = await web3.eth.call({
                to: contractAddress(network, contractTypeFromToken(token)),
                data: `0x70a08231000000000000000000000000${address.slice(-40)}`,
            });
            return new BigNumber(bal);
        }
    } catch (err) {
        // console.error(err);
        return new BigNumber(0);
    }
}

function useBalances() {
    const { connector, library, chainId, account } = useWeb3React();
    const [balances, setBalances] = useState(new Map<Token, BigNumber>());

    const updateBalances = useCallback(async () => {
        if (!library || !chainId) {
            return;
        }

        const balances = new Map<Token, BigNumber>();
        if (!account) {
            for (const token of Tokens) {
                balances.set(token, new BigNumber(0));
            }
            setBalances(balances);
            return;
        }

        for (const token of Tokens) {
            balances.set(token, await balanceOf(library, networkIdFromChainId(chainId), token, account));
        }
        setBalances(balances);
    }, [library, chainId, account, connector]);

    useEffect(() => {
        updateBalances();
        const interval = setInterval(() => {
            updateBalances();
        }, 20000);
        return () => interval && clearInterval(interval);
    }, [connector, library, chainId, account, updateBalances]);

    return { balances, updateBalances };
}

const Balances = createContainer(useBalances);

export default Balances;
