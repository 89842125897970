/* eslint-disable react/jsx-key */
import React from 'react';
import { useTable } from 'react-table';

interface Props {
    columns: any;
    data: any;
    onRowClick: (row: any) => void;
}

const CustomTable: React.FC<Props> = ({ columns, data, onRowClick }: Props) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });

    return (
        <table {...getTableProps()} className="pools-table">
            <thead className="pools-table-header">
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} className="pools-table-head-row">
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} className="pools-table-header-cell">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className="pools-table-body-row" onClick={() => onRowClick(row)}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} className="pools-table-row-cell">
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default CustomTable;
