import * as React from 'react';

import Dashboard from '../pages/Dashboard';
import Poolinfo from '../pages/Poolinfo';
import Trade from '../pages/Trade';
import Borrow from '../pages/Borrow';
import AppWrapper from './AppWrapper';
import { BrowserRouter, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { kovanClient, mainnetClient } from '../lib/graphql';
import Balances from '../containers/balances';
import Wallet from '../containers/wallet';
import GraphInfo from '../containers/graphInfo';
import Withdrawer from '../containers/withdrawer';
import Depositor from '../containers/depositor';
import Liquidity from '../containers/liquidity';
import { Borrower, Supplier } from '../containers/borrow';
import RookBalances from '../containers/rookBalances';
import ExchangeRates from '../containers/exchangeRates';
import GA from '../containers/analytics';
import { useWeb3React } from '@web3-react/core';
import { NetworkID, networkIdFromChainId } from '../lib/network';
import { Web3Provider, Web3ProviderNetwork } from './Web3Provider';
import { IProps } from '../lib/types';
import { Compose } from 'provider-compose';

function GraphProvider({ children }: IProps) {
    const { chainId } = useWeb3React();
    const networkId = networkIdFromChainId(chainId);

    const client = (() => {
        switch (networkId) {
            case NetworkID.Mainnet:
                return mainnetClient;
            case NetworkID.Kovan:
                return kovanClient;
            default:
                return mainnetClient;
        }
    })();

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

const store = [
    GraphInfo.Provider,
    Wallet.Provider,
    Balances.Provider,
    RookBalances.Provider,
    Depositor.Provider,
    Withdrawer.Provider,
    Borrower.Provider,
    Supplier.Provider,
    Liquidity.Provider,
    ExchangeRates.Provider,
];

function ContextProviders({ children }: IProps) {
    return (
        <Web3Provider>
            <Web3ProviderNetwork>
                <GraphProvider>
                    <Compose providers={store}>{children}</Compose>
                </GraphProvider>
            </Web3ProviderNetwork>
        </Web3Provider>
    );
}

export default function App(): JSX.Element {
    return (
        <ContextProviders>
            <BrowserRouter basename="/">
                <GA.Provider>
                    <AppWrapper>
                        <Route path="/" exact render={() => <Dashboard />} />
                        <Route path="/pool/:id" exact render={(props) => <Poolinfo {...props} />} />
                        {/* <Route path="/trade" exact render={() => <Trade />} />*/}
                        {/* <Route path="/borrow" exact render={() => <Borrow />} /> */}
                    </AppWrapper>
                </GA.Provider>
            </BrowserRouter>
        </ContextProviders>
    );
}
