import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { UnderlyingToken, Token, underlyingTokenToKToken, wrapKToken, wrapUnderlyingToken } from '../../lib/tokens';
import { tokenAddress } from '../../lib/contracts';
import BigNumber from 'bignumber.js';
import { NetworkID, networkIdFromChainId } from '../../lib/network';
import { useWeb3React } from '@web3-react/core';
import { tokenID } from '../../lib/graphql';

interface TokenBalanceResponse {
    userBalance: TokenBalance;
}

interface UserProfitResponse {
    userProfit: { profit: string };
}

interface TokenBalance {
    balance: string;
}

interface Variables {
    id: string;
}

interface TokenListVariables {
    id1: string;
    id2: string;
    id3: string;
    id4: string;
    id5: string;
}

interface UnderlyingBalanceVariables {
    user_id: string;
    token_id: string;
    pool_id: string;
}

interface UnderlyingBalanceResponse {
    kTokenBalance: { balance: string; updatedAt: string };
    kTokenSupply: { totalSupply: string };
    poolSupply: { supply: string; updatedAt: string }[];
}

interface TokenListBalanceResponse {
    kethBalance: { balance: string };
    kwethBalance: { balance: string };
    kdaiBalance: { balance: string };
    kusdcBalance: { balance: string };
    kbtcBalance: { balance: string };
}

interface BalanceListVariables {
    ids: string[];
}

export function useBalancesOfTokenList() {
    const { chainId, account } = useWeb3React();

    const { data } = useQuery<TokenListBalanceResponse, TokenListVariables>(
        gql`
            query getTokenListBalance($id1: ID!, $id2: ID!, $id3: ID, $id4: ID!, $id5: ID!) {
                kethBalance: userBalance(id: $id1) {
                    balance
                }
                kwethBalance: userBalance(id: $id2) {
                    balance
                }
                kdaiBalance: userBalance(id: $id3) {
                    balance
                }
                kusdcBalance: userBalance(id: $id4) {
                    balance
                }
                kbtcBalance: userBalance(id: $id5) {
                    balance
                }
            }
        `,
        {
            variables: {
                id1: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(UnderlyingToken.ETH)),
                    account,
                    networkIdFromChainId(chainId),
                ),
                id2: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(UnderlyingToken.WETH)),
                    account,
                    networkIdFromChainId(chainId),
                ),
                id3: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(UnderlyingToken.DAI)),
                    account,
                    networkIdFromChainId(chainId),
                ),
                id4: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(UnderlyingToken.USDC)),
                    account,
                    networkIdFromChainId(chainId),
                ),
                id5: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(UnderlyingToken.BTC)),
                    account,
                    networkIdFromChainId(chainId),
                ),
            },
            pollInterval: 20000,
        },
    );

    return {
        kethBalance: data && data.kethBalance ? new BigNumber(data.kethBalance.balance) : new BigNumber(0),
        kwethBalance: data && data.kwethBalance ? new BigNumber(data.kwethBalance.balance) : new BigNumber(0),
        kdaiBalance: data && data.kdaiBalance ? new BigNumber(data.kdaiBalance.balance) : new BigNumber(0),
        kusdcBalance: data && data.kusdcBalance ? new BigNumber(data.kusdcBalance.balance) : new BigNumber(0),
        kbtcBalance: data && data.kbtcBalance ? new BigNumber(data.kbtcBalance.balance) : new BigNumber(0),
    };
}

export function useBalance(selectedToken: UnderlyingToken) {
    const { chainId, account } = useWeb3React();

    const { data } = useQuery<TokenBalanceResponse, Variables>(
        gql`
            query getBalance($id: ID!) {
                userBalance(id: $id) {
                    balance
                }
            }
        `,
        {
            variables: {
                id: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(selectedToken)),
                    account,
                    networkIdFromChainId(chainId),
                ),
            },
            pollInterval: 10000,
        },
    );

    return {
        balance: data && data.userBalance ? new BigNumber(data.userBalance.balance) : new BigNumber(0),
    };
}

export function useUnderlyingBalance(selectedToken: UnderlyingToken) {
    const { chainId, account } = useWeb3React();

    const { data, loading, error } = useQuery<UnderlyingBalanceResponse, UnderlyingBalanceVariables>(
        gql`
            query getBalance($user_id: ID!, $token_id: ID!, $pool_id: ID!) {
                kTokenBalance: userBalance(id: $user_id) {
                    balance
                    updatedAt
                }

                kTokenSupply: ktoken(id: $token_id) {
                    totalSupply
                }

                poolSupply: liquidityPoolSupplies(where: { underlyingToken: $pool_id }) {
                    supply
                    updatedAt
                }
            }
        `,
        {
            variables: {
                user_id: userBalanceID(
                    wrapKToken(underlyingTokenToKToken(selectedToken)),
                    account,
                    networkIdFromChainId(chainId),
                ),
                token_id: tokenID(wrapKToken(underlyingTokenToKToken(selectedToken)), networkIdFromChainId(chainId)),
                pool_id: tokenID(wrapUnderlyingToken(selectedToken), networkIdFromChainId(chainId)),
            },
            pollInterval: 10000,
            skip: !chainId || !account,
        },
    );

    console.log('user Balance updated', data?.kTokenBalance?.balance);

    if (!data || !data.kTokenBalance || !data.kTokenSupply || !data.poolSupply || error) {
        return {
            balance: new BigNumber(0),
        };
    }

    return {
        balance: calculateUserBalance(
            new BigNumber(data.kTokenBalance.balance),
            new BigNumber(data.kTokenSupply.totalSupply),
            new BigNumber(data.poolSupply[0].supply),
        ),
    };
}

export function useLazyUnderlyingBalance() {
    return useLazyQuery<UnderlyingBalanceResponse, UnderlyingBalanceVariables>(
        gql`
            query getBalance($user_id: ID!, $token_id: ID!, $pool_id: ID!) {
                kTokenBalance: userBalance(id: $user_id) {
                    balance
                    updatedAt
                }

                kTokenSupply: ktoken(id: $token_id) {
                    totalSupply
                }

                poolSupply: liquidityPoolSupplies(where: { underlyingToken: $pool_id }) {
                    supply
                    updatedAt
                }
            }
        `,
        {
            fetchPolicy: 'network-only',
            // onCompleted: (data) => {
            //     console.log('CALLED USER BALANCE');
            // },
        },
    );
}

export function useEarnings(selectedToken: UnderlyingToken) {
    const { chainId, account } = useWeb3React();

    const { data, loading } = useQuery<UserProfitResponse, Variables>(
        gql`
            query getEarnings($id: ID!) {
                userProfit(id: $id) {
                    profit
                }
            }
        `,
        {
            variables: {
                id: userBalanceID(wrapUnderlyingToken(selectedToken), account, networkIdFromChainId(chainId)),
            },
            pollInterval: 10000,
        },
    );

    return {
        earnings: data && data.userProfit ? new BigNumber(data.userProfit.profit) : new BigNumber(0),
        loading,
    };
}

export function getLazyOptions(token: UnderlyingToken, account: string, chainId: number) {
    return {
        variables: {
            user_id: userBalanceID(wrapKToken(underlyingTokenToKToken(token)), account, networkIdFromChainId(chainId)),
            token_id: tokenID(wrapKToken(underlyingTokenToKToken(token)), networkIdFromChainId(chainId)),
            pool_id: tokenID(wrapUnderlyingToken(token), networkIdFromChainId(chainId)),
        },
        // pollInterval: 5000,
    };
}

function userBalanceID(selectedToken: Token, address: string | null | undefined, network: NetworkID | null) {
    return address && network ? address.toLowerCase() + tokenAddress(network, selectedToken).toLowerCase() : '';
}

export function calculateUserBalance(kTokens: BigNumber, kTokensSupply: BigNumber, poolSupply: BigNumber): BigNumber {
    // console.log('kTokens: ', kTokens.toFormat());
    // console.log('kTokensSupply: ', kTokensSupply.toFormat());
    // console.log('poolSupply: ', poolSupply.toFormat());
    const tokenBalance = kTokens.dividedBy(kTokensSupply).multipliedBy(poolSupply);
    // console.log('Token Balance: ', tokenBalance.toFormat());
    return tokenBalance;
}
