/* eslint-disable react/jsx-key */
import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    UnderlyingToken,
    UnderlyingTokens,
    underlyingTokenSymbol,
    Token,
    tokenBalanceWithDecimals,
} from '../lib/tokens';
import BarLoader from 'react-spinners/BarLoader';
import TokenSVG from './TokenSVG';
import CustomTable from './CustomTable';
import Liquidity from '../containers/liquidity';
import ExchangeRates from '../containers/exchangeRates';
import { getAPYRequest, tokenID } from '../lib/graphql';
import BigNumber from 'bignumber.js';
import { calculateRequiredBlocksHistory } from '../lib/charts';
import { networkIdFromChainId } from '../lib/network';
import { calculateUserBalance, calculatePoolAPY, calculateRookAPY } from '../lib/utils';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';

type IBalances<T> = { [key in keyof typeof UnderlyingToken]: T };

function tokenLabel(underlyingToken: UnderlyingToken) {
    return (
        <div className="icon-label">
            <TokenSVG underlyingToken={underlyingToken} />
            <span>{underlyingTokenSymbol(underlyingToken)}</span>
        </div>
    );
}

function makeRowsForPoolsTable(
    exchange?: IBalances<BigNumber> | null,
    tokenLiquidity?: IBalances<BigNumber> | null,
    balances?: IBalances<BigNumber> | null,
    apys?: IBalances<string> | null,
) {
    return [
        ...UnderlyingTokens.map((token) => {
            const liquidity =
                tokenLiquidity &&
                tokenLiquidity[UnderlyingToken[token]] &&
                exchange &&
                exchange[UnderlyingToken[token]] ? (
                    '$' +
                    tokenLiquidity[UnderlyingToken[token]]
                        .multipliedBy(exchange[UnderlyingToken[token]])
                        .toFormat(0, BigNumber.ROUND_DOWN)
                ) : (
                    <BarLoader color="#353635" loading={true} width={40} />
                );
            const balance =
                balances && balances[UnderlyingToken[token]] ? (
                    balances[UnderlyingToken[token]].toFormat() // balances[UnderlyingToken[token]].toFormat(4, BigNumber.ROUND_DOWN)
                ) : (
                    <BarLoader color="#353635" loading={true} width={40} />
                );

            const apy =
                apys && apys[UnderlyingToken[token]] ? (
                    apys[UnderlyingToken[token]]
                ) : (
                    <BarLoader color="#353635" loading={true} width={40} />
                );
            return {
                col1: tokenLabel(token),
                col2: apy,
                col3: liquidity,
                col4: balance,
                token,
            };
        }),
    ];
}

const PoolsTableWrapper: React.FC = () => {
    const { t } = useTranslation();
    const columns = useMemo(() => {
        return [
            {
                Header: t('table-pool.asset').toUpperCase(),
                accessor: 'col1',
            },
            {
                Header: t('table-pool.liquidity').toUpperCase(),
                accessor: 'col3',
                sortType: 'basic',
            },
            {
                Header: t('table-pool.balance').toUpperCase(),
                accessor: 'col4',
                sortType: 'basic',
            },
        ];
    }, [t]);
    const [data, setData] = useState(() => makeRowsForPoolsTable());
    const liquidity = Liquidity.useContainer();
    const exchange = ExchangeRates.useContainer();
    const [poolAPYs, setPoolAPYs] = useState<IBalances<string>>({
        ETH: '0',
        WETH: '0',
        DAI: '0',
        USDC: '0',
        BTC: '0',
    });

    const { library, chainId } = useWeb3React();
    const { library: networkWeb3, chainId: networkChainId } = useWeb3React('infura');

    const history = useHistory();

    const handleRowClick = (row: { original: any }) => {
        history.push(`/pool/${UnderlyingToken[row.original.token]}`);
    };

    useEffect(() => {
        async function getAPYs() {
            if ((!library && !networkWeb3) || (!chainId && !networkChainId) || !exchange['ROOK'].gt(0)) {
                return;
            }
            const web3 = !!library ? library : networkWeb3;
            const chain = library && chainId ? chainId : networkChainId;
            const net = networkIdFromChainId(chain);
            const latestBlock = await web3.eth.getBlockNumber();
            const blockWeekAgo = calculateRequiredBlocksHistory(latestBlock, 7, net)[0];
            const apysData = (
                await Promise.all(
                    [Token.ETH, Token.WETH, Token.DAI, Token.USDC, Token.BTC].map(async (token) => {
                        const { data, error } = await getAPYRequest(blockWeekAgo, tokenID(token, net), net);
                        if (error) {
                            console.error('APY error', error);
                            return {
                                [Token[token]]: '0.0%',
                            };
                        }
                        const tokenSupply = new BigNumber(data.last[0].deposited).minus(
                            new BigNumber(data.last[0].withdrawn),
                        );
                        const { number: rookAPY } = calculateRookAPY(
                            net,
                            tokenSupply,
                            exchange[Token[token]],
                            exchange['ROOK'],
                            token,
                        );

                        return {
                            [Token[token]]: `${rookAPY.toFormat(2)}%`,
                        };
                    }),
                )
            ).reduce((acc, t) => {
                const key = Object.keys(t)[0];
                return { ...acc, [key]: t[key] };
            }, {});

            setPoolAPYs({
                ETH: apysData['ETH'],
                WETH: apysData['WETH'],
                DAI: apysData['DAI'],
                USDC: apysData['USDC'],
                BTC: apysData['BTC'],
            });
        }
        getAPYs();
    }, [library, chainId, networkWeb3, networkChainId, exchange]);

    useEffect(() => {
        if (!(exchange[UnderlyingToken[UnderlyingToken.ETH]].toNumber() > 0)) {
            return;
        }

        if (poolAPYs[UnderlyingToken[UnderlyingToken.ETH]] === '0') {
            return;
        }
        const poolLiq: IBalances<BigNumber> = {
            ETH: tokenBalanceWithDecimals(Token.ETH, liquidity.ethPoolBalance),
            WETH: tokenBalanceWithDecimals(Token.WETH, liquidity.wethPoolBalance),
            DAI: tokenBalanceWithDecimals(Token.DAI, liquidity.daiPoolBalance),
            USDC: tokenBalanceWithDecimals(Token.USDC, liquidity.usdcPoolBalance),
            BTC: tokenBalanceWithDecimals(Token.BTC, liquidity.btcPoolBalance),
        };

        const balances: IBalances<BigNumber> = {
            ETH: calculateUserBalance(Token.ETH, liquidity.kethBalance, liquidity.ethPoolBalance, liquidity.kethSupply),
            WETH: calculateUserBalance(
                Token.WETH,
                liquidity.kwethBalance,
                liquidity.wethPoolBalance,
                liquidity.kwethSupply,
            ),
            DAI: calculateUserBalance(Token.DAI, liquidity.kdaiBalance, liquidity.daiPoolBalance, liquidity.kdaiSupply),
            USDC: calculateUserBalance(
                Token.USDC,
                liquidity.kusdcBalance,
                liquidity.usdcPoolBalance,
                liquidity.kusdcSupply,
            ),
            BTC: calculateUserBalance(Token.BTC, liquidity.kbtcBalance, liquidity.btcPoolBalance, liquidity.kbtcSupply),
        };

        const data = makeRowsForPoolsTable(exchange, poolLiq, balances, poolAPYs);
        setData(data);
    }, [exchange, poolAPYs, liquidity]);

    return (
        <>
            <CustomTable data={data} columns={columns} onRowClick={handleRowClick} />
        </>
    );
};

export default PoolsTableWrapper;
