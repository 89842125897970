import BigNumber from 'bignumber.js';
import { Token, tokenBalanceWithDecimals, tokenDecimals } from '../lib/tokens';
import { NetworkID } from '../lib/network';
import config from './config';

const DAILY_HOURS = 24;
const YEAR = 365;
const WEEK = 7;
const BLOCKS_PER_YEAR = 2425790;

interface APY {
    percent: string;
    number: BigNumber;
}

export enum BlocksPeriod {
    MINUTE,
    HOUR,
    DAY,
    WEEK,
    YEAR,
}

export function calculateBlocksPerPeriod(network: NetworkID, length: BlocksPeriod): number {
    const speed = network === NetworkID.Mainnet ? 12 : 4;
    const perMinute = 60 / speed;
    const perHour = Math.round(perMinute * 60);

    switch (length) {
        case BlocksPeriod.MINUTE:
            return Math.round(perMinute);
        case BlocksPeriod.HOUR:
            return perHour;
        case BlocksPeriod.DAY:
            return perHour * DAILY_HOURS;
        case BlocksPeriod.WEEK:
            return perHour * DAILY_HOURS * WEEK;
        case BlocksPeriod.YEAR:
            return perHour * DAILY_HOURS * YEAR;
        default:
            return perHour;
    }
}

/* The formula for calculating user balance 
        balance = kTokenBalance * TokensSupplied / kTokenSupply
    */
export function calculateUserBalance(
    token: Token,
    kBalance: BigNumber,
    poolBalance: BigNumber,
    kSupply: BigNumber,
): BigNumber {
    const balance = kBalance.multipliedBy(poolBalance).dividedBy(kSupply);
    return tokenBalanceWithDecimals(token, balance);
}

export function calculatePoolAPY(data: { last: any[]; weekAgo: any[] }): APY {
    const last = data.last[0];
    const weekAgo = data.weekAgo[0];
    if (!last || !weekAgo) return { percent: '0.0%', number: new BigNumber(0) };
    const weeklyProfit = new BigNumber(last.earned).minus(new BigNumber(weekAgo.earned));
    const depositDelta = new BigNumber(last.deposited).minus(new BigNumber(last.withdrawn));
    if (weeklyProfit.lte(new BigNumber(0)) || depositDelta.lte(new BigNumber(0)))
        return { percent: '0.0%', number: new BigNumber(0) };
    const apy = weeklyProfit.dividedBy(depositDelta).multipliedBy(100);
    if (apy.lte(new BigNumber(0.01))) return { percent: '0.0%', number: new BigNumber(0) };
    return { percent: `${apy.toFormat(2)}%`, number: apy };
}

// (REWARD_PER_BLOCK * BLOCKS_PER_YEAR * KPR_PRICE_IN_USD) / (POOL_LIQUIDITY * POOL_TOKEN_PRICE_IN_USD)
export function calculateRookAPY(
    network: NetworkID,
    poolLiquidity: BigNumber,
    tokenPrice: BigNumber,
    rookPrice: BigNumber,
    token: Token,
): APY {
    const decimals = tokenDecimals(token);
    const rewardPerBlock = config['ROOK_REWARDS_PRE_MINING']
        ? new BigNumber(config['ROOK_REWARDS_PRE_MINING'])
        : new BigNumber(0);
    // const blocksPerYear = new BigNumber(calculateBlocksPerPeriod(network, BlocksPeriod.YEAR));

    const apy = rewardPerBlock
        .multipliedBy(new BigNumber(BLOCKS_PER_YEAR))
        .multipliedBy(rookPrice)
        .dividedBy(poolLiquidity.multipliedBy(tokenPrice).div(new BigNumber(10).pow(decimals)))
        .multipliedBy(100);

    if (apy.lt(new BigNumber(0.1)) || !isFinite(apy.toNumber())) return { percent: '0.0%', number: new BigNumber(0) };
    return { percent: `${apy.toFormat(2)}%`, number: apy };
}
