import moment, { Moment } from 'moment';
import BigNumber from 'bignumber.js';
import { range } from 'lodash';
import { UnderlyingToken, wrapUnderlyingToken, Token, tokenBalanceWithDecimals } from './tokens';
import { BlocksPeriod, calculateBlocksPerPeriod } from './utils';
import { NetworkID } from './network';

export enum ChartLength {
    day = 1,
    week = 7,
    all = 0,
}

export enum ChartDataType {
    Profit = 'profit',
    Balance = 'balance',
    Borrow = 'amount',
}

export interface GraphVariables {
    token: string;
}

export interface ChartRawTx {
    time: string;
    profit?: string;
    balance?: string;
}

export interface LiquidityPoolTraceTx {
    updatedAt: string;
    earned: string;
    deposited: string;
    borrowed: string;
    withdrawn: string;
}

export interface ChartDataTx {
    name: string;
    value: number;
    token: Token;
}

export function getIntervalForLength(len: number, historyLen: number | undefined): number {
    switch (len) {
        case ChartLength.day:
            return 3;
        case ChartLength.week:
            return 1;
        case ChartLength.all:
            if (!historyLen || historyLen < 5) {
                return 0;
            } else {
                return Math.round(historyLen / 5);
            }
        default:
            return 1;
    }
}

function getValForType(type: ChartDataType, tx: LiquidityPoolTraceTx) {
    switch (type) {
        case ChartDataType.Balance:
            return new BigNumber(tx.deposited).minus(new BigNumber(tx.withdrawn));
        case ChartDataType.Borrow:
            return new BigNumber(tx.borrowed);
        case ChartDataType.Profit:
            return new BigNumber(tx.earned);
        default:
            return new BigNumber(0);
    }
}

export function calculateRequiredBlocksHistory(
    currentBlock: number,
    length: ChartLength,
    network: NetworkID,
): number[] {
    const AVERAGE_HOUR_BLOCKS = calculateBlocksPerPeriod(network, BlocksPeriod.HOUR);
    const AVERAGE_DAILY_BLOCKS = calculateBlocksPerPeriod(network, BlocksPeriod.DAY);
    const AVERAGE_WEEKLY_BLOCKS = calculateBlocksPerPeriod(network, BlocksPeriod.WEEK);
    const AVERAGE_YEARLY_BLOCKS = calculateBlocksPerPeriod(network, BlocksPeriod.YEAR);

    let delta = AVERAGE_YEARLY_BLOCKS;

    switch (length) {
        case ChartLength.all:
            // return blocks for a year;
            return range(currentBlock, currentBlock - delta, -AVERAGE_WEEKLY_BLOCKS).reverse();
        case ChartLength.week:
            // return blocks for a week;
            delta = AVERAGE_WEEKLY_BLOCKS;
            return range(currentBlock, currentBlock - delta, -AVERAGE_DAILY_BLOCKS).reverse();
        case ChartLength.day:
            // return blocks for a day;
            delta = AVERAGE_DAILY_BLOCKS;
            return range(currentBlock, currentBlock - delta, -AVERAGE_HOUR_BLOCKS).reverse();
        default:
            delta = AVERAGE_WEEKLY_BLOCKS;
            return range(currentBlock, currentBlock - delta, -AVERAGE_DAILY_BLOCKS).reverse();
    }
}

export function makeChartData(
    txs: LiquidityPoolTraceTx[],
    token: Token,
    type: ChartDataType,
    length: ChartLength,
): ChartDataTx[] {
    const timeNow = moment();
    const chartData = txs.map((tx, index) => {
        const val = getValForType(type, tx);
        // updatedAt does not represent the actual time of the trace.
        return {
            time: lengthToTimeIndex(length, index, txs.length, timeNow),
            value: tokenBalanceWithDecimals(token, val).toNumber(),
            token: token,
        };
    });

    const maped = chartData.reduce((accum: ChartDataTx[], curr) => {
        const axiesY =
            length === 1 ? moment().hour(curr.time).format('HH') : moment().dayOfYear(curr.time).format('D MMM');
        const end = accum.length - 1;
        if (end < 0) {
            accum.push({ name: axiesY, value: curr.value, token: curr.token });
        } else {
            if (accum[end].name === axiesY) {
                accum[end].value = curr.value;
            } else {
                accum.push({ name: axiesY, value: curr.value, token: curr.token });
            }
        }
        return accum;
    }, []);

    return maped;
}

function lengthToTimeIndex(length: ChartLength, index: number, txlength: number, time: Moment) {
    switch (length) {
        case ChartLength.day:
            return time.hour() + (index - txlength + 1);
        case ChartLength.week:
            return time.dayOfYear() + (index - txlength + 1);
        case ChartLength.all:
            return time.dayOfYear() + (index - txlength + 1) * 7;
    }
}

export function getAggregateVolume(txs: ChartDataTx[] | undefined, length: ChartLength): string {
    if (!txs) return '0';
    const vol = txs.reduce((agg: number, cur: ChartDataTx) => {
        return agg + cur.value;
    }, 0);
    return new BigNumber(vol).toFormat(2);
}

export function straightLineData(underlyingToken: UnderlyingToken, value?: number) {
    const val = value ? value : 0;
    return [
        {
            name: moment().subtract('6', 'day').format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
        {
            name: moment().subtract('5', 'day').format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
        {
            name: moment().subtract('4', 'day').format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
        {
            name: moment().subtract('3', 'day').format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
        {
            name: moment().subtract('2', 'day').format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
        {
            name: moment().subtract('1', 'day').format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
        {
            name: moment().format('D MMM'),
            value: val,
            token: wrapUnderlyingToken(underlyingToken),
        },
    ];
}
