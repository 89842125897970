/* eslint-disable react/jsx-key */
import React, { useMemo, useState, useEffect } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { UnderlyingToken, tokenDecimals, symbolToToken, wrapUnderlyingToken } from '../lib/tokens';
import CustomTable from './CustomTable';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { getTopEarners, getTopKeepers } from '../lib/graphql';
import { networkIdFromChainId } from '../lib/network';
import ExchangeRates from '../containers/exchangeRates';
import { useWeb3React } from '@web3-react/core';

interface IEarnings {
    address: string;
    earnings: BigNumber;
}
enum TabChoice {
    // keepers,
    lps,
}
type IBalances<T> = { [key in keyof typeof UnderlyingToken]: T };

function makeRowsForEarnersTable(earnings?: IEarnings[]) {
    if (earnings) {
        return [
            ...earnings?.map((keeper, i) => {
                return {
                    col1: `${i + 1}`,
                    col2: `${keeper.address.slice(0, 10)}...${keeper.address.slice(-10)}`,
                    col3: `$${keeper.earnings.toFormat(2)}`,
                    address: keeper.address,
                };
            }),
        ];
    }
    return [];
}

const EarnersTableWrapper: React.FC = () => {
    const { t } = useTranslation();
    const { library, chainId } = useWeb3React();
    const { library: networkWeb3, chainId: networkChainId } = useWeb3React('infura');
    const [data, setData] = useState(() => makeRowsForEarnersTable());
    const exchange = ExchangeRates.useContainer();
    const [tabChoice, setTabChoice] = useState<TabChoice>(TabChoice.lps);
    const [loading, setLoading] = useState(false);

    const columns = useMemo(() => {
        return [
            {
                Header: t('table-earners.position').toUpperCase(),
                accessor: 'col1',
            },
            {
                Header: t('table-earners.address').toUpperCase(),
                accessor: 'col2',
                sortType: 'basic',
            },
            {
                Header:
                    tabChoice === TabChoice.lps
                        ? t('table-earners.lp-earnings').toUpperCase()
                        : t('table-earners.keeper-earnings').toUpperCase(),
                accessor: 'col3',
                sortType: 'basic',
            },
        ];
    }, [t, tabChoice]);

    async function parseTopEarners() {
        if ((!library && !networkWeb3) || (!chainId && !networkChainId) || !exchange.ETH.gt(new BigNumber(0))) {
            return;
        }
        const network = chainId ? chainId : networkChainId;
        try {
            setLoading(true);
            const { data } = await getTopEarners(networkIdFromChainId(network));
            const userEarnings = Object.keys(data).reduce((_earners, _token) => {
                const earnersListForToken = data[_token];
                const earners = earnersListForToken.reduce(
                    (tokenEarners: any, earner: { user: { id: string }; profit: string }) => {
                        const address = earner.user.id;
                        const profit = earner.profit;
                        const token = symbolToToken(_token);
                        const decimals = tokenDecimals(wrapUnderlyingToken(token));
                        const exchangeRate = exchange[UnderlyingToken[token]];
                        const usdProfit = new BigNumber(profit)
                            .multipliedBy(exchangeRate)
                            .dividedBy(new BigNumber(10).pow(decimals));

                        const userProfit = !_earners[address] ? usdProfit : _earners[address].plus(usdProfit);
                        const updatedUserProfit = { [address]: userProfit };

                        return {
                            ...tokenEarners,
                            ...updatedUserProfit,
                        };
                    },
                    {},
                );
                return {
                    ..._earners,
                    ...earners,
                };
            }, {});
            const sortedList = Object.keys(userEarnings)
                .map((address) => {
                    return {
                        address,
                        earnings: userEarnings[address],
                    };
                })
                .sort((a, b) => b.earnings.minus(a.earnings).toNumber())
                .slice(0, 5);

            setData(makeRowsForEarnersTable(sortedList));
            setLoading(false);
        } catch (err) {
            setLoading(false);
            return;
        }
    }
    async function parseTopKeepers() {
        if ((!library && !networkWeb3) || (!chainId && !networkChainId) || !exchange.ETH.gt(new BigNumber(0))) {
            return;
        }
        const network = chainId ? chainId : networkChainId;
        try {
            setLoading(true);
            const { data } = await getTopKeepers(networkIdFromChainId(network));
            const keeperEarnings = Object.keys(data).reduce((_keepers, _token) => {
                const keepersListForToken = data[_token];
                const keepers = keepersListForToken.reduce(
                    (tokenKeepers: any, keeper: { id: string; profit: string }) => {
                        const address = keeper.id.slice(0, 42);
                        const profit = keeper.profit;
                        const token = symbolToToken(_token);
                        const decimals = tokenDecimals(wrapUnderlyingToken(token));
                        const exchangeRate = exchange[UnderlyingToken[token]];
                        const usdProfit = new BigNumber(profit)
                            .multipliedBy(exchangeRate)
                            .dividedBy(new BigNumber(10).pow(decimals));

                        const keeperProfit = !_keepers[address] ? usdProfit : _keepers[address].plus(usdProfit);
                        const updatedKeeperProfit = { [address]: keeperProfit };

                        return {
                            ...tokenKeepers,
                            ...updatedKeeperProfit,
                        };
                    },
                    {},
                );
                return {
                    ..._keepers,
                    ...keepers,
                };
            }, {});
            const sortedList = Object.keys(keeperEarnings)
                .map((address) => {
                    return {
                        address,
                        earnings: keeperEarnings[address],
                    };
                })
                .sort((a, b) => b.earnings.minus(a.earnings).toNumber())
                .slice(0, 5);

            setData(makeRowsForEarnersTable(sortedList));
            setLoading(false);
        } catch (err) {
            return;
            setLoading(false);
        }
    }

    useEffect(() => {
        tabChoice === TabChoice.lps ? parseTopEarners() : parseTopKeepers();
    }, [library, chainId, exchange, networkWeb3, networkChainId, tabChoice]);

    const handleRowClick = (row: { original: any }) => {
        const address = row.original.address;
        navigator.clipboard?.writeText(address);
    };

    return (
        <div className="earners-table">
            {/* <div className="earners-table-tabs">
                { <div className="earners-table-header" onClick={() => setTabChoice(TabChoice.keepers)}>
                    <h3 className={tabChoice === TabChoice.keepers ? 'chosen' : ''}>
                        {t('table-earners.top-keepers-header').toUpperCase()}
                    </h3>
                </div>
                <span>/</span> }
                <div className="earners-table-header" onClick={() => setTabChoice(TabChoice.lps)}>
                    <h3 className={tabChoice === TabChoice.lps ? 'chosen' : ''}>
                        {t('table-earners.top-lp-header').toUpperCase()}
                    </h3>
                </div>
            </div>
            {loading ? (
                <div className="spinner-wrapper">
                    <ScaleLoader width={3} height={25} color="#09Af73" loading={true} />
                </div>
            ) : (
                <CustomTable data={data} columns={columns} onRowClick={handleRowClick} />
            )} */}
        </div>
    );
};

export default EarnersTableWrapper;
