import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';
import TokenSVG from './TokenSVG';
import {
    // useUnderlyingBalance,
    useEarnings,
    useLazyUnderlyingBalance,
    getLazyOptions,
    calculateUserBalance,
} from '../containers/subscriptions/user';
import WithdrawModal from './modals/WithdrawModal';
// import DepositModal from './modals/DepositModal';
import Withdrawer, { WithdrawerState } from '../containers/withdrawer';
import Depositor, { DepositorState } from '../containers/depositor';
// import Balances from '../containers/balances';
import { UnderlyingToken, wrapUnderlyingToken, displayTokenBalance } from '../lib/tokens';
import BigNumber from 'bignumber.js';
import { HistoryResponse, Tx } from '../containers/subscriptions/transactions';
import { usePrevious } from '../hooks';
import PuffLoader from 'react-spinners/PuffLoader';
import config from '../lib/config';

interface Props {
    underlyingToken: UnderlyingToken;
    transactions: HistoryResponse | undefined;
    transactionsLoading?: boolean;
}

interface IBalances {
    kTokenBalance: BigNumber;
    kTokenTotalSupply: BigNumber;
    poolAssets: BigNumber;
    undelyingTokenBalance?: BigNumber;
}

export default function UserActions({ underlyingToken, transactions }: Props) {
    const { t } = useTranslation();
    const { library, chainId, account } = useWeb3React();
    const depositor = Depositor.useContainer();
    const withdrawer = Withdrawer.useContainer();
    // const { balance } = useLazyUnderlyingBalance(underlyingToken);
    const { earnings, loading: earningsLoading } = useEarnings(underlyingToken);
    const [balance, setBalances] = useState(new BigNumber(0));
    // const { updateBalances } = Balances.useContainer();
    const [
        getBalance,
        { data: balanceData, loading: loadingBalance, error: errorBalance },
    ] = useLazyUnderlyingBalance();
    const [collectedFees, setCollectedFees] = useState<BigNumber>(new BigNumber(0));
    const [showLoadingBalance, setLoadingBalance] = useState(true);
    const prevFees = usePrevious<BigNumber>(collectedFees);
    const prevBalance = usePrevious(balanceData);

    useEffect(() => {
        if (!account || !library || !chainId) {
            return;
        }
        setLoadingBalance(true);
        getBalance(getLazyOptions(underlyingToken, account, chainId));
        setLoadingBalance(false);

        const interval = setInterval(() => {
            getBalance(getLazyOptions(underlyingToken, account, chainId));
        }, 20000);
        return () => interval && clearInterval(interval);
    }, [underlyingToken, library, chainId, account, getBalance]);

    // useEffect(() => {
    //     function switchOffAfter5Sec() {
    //         return setTimeout(() => {
    //             setLoadingBalance(false);
    //             updateBalances();
    //         }, 0);
    //     }
    //     let timeout;
    //     if (showLoadingBalance) {
    //         timeout = switchOffAfter5Sec();
    //     }
    //     return () => {
    //         timeout && clearTimeout(timeout);
    //     };
    // }, [showLoadingBalance]);

    useEffect(() => {
        if (balanceData && balanceData.kTokenBalance) {
            const calcBalance = calculateUserBalance(
                new BigNumber(balanceData.kTokenBalance.balance),
                new BigNumber(balanceData.kTokenSupply.totalSupply),
                new BigNumber(balanceData.poolSupply[0].supply),
            );
            setBalances(calcBalance);
        } else {
            setBalances(new BigNumber(0));
        }
    }, [balanceData]);

    const onDepositClick = () => {
        if (library && chainId) {
            depositor.setState(DepositorState.ACCEPT_FEE);
        }
    };

    useEffect(() => {
        if (transactions && prevFees) {
            //check if balances actually changed to avoid unncessary re-render
            const { deposits, withdrawals } = transactions;
            const sumOfDeposits = sumAmounts(deposits);
            const fee = chainId === 1 ? config.MAINNET_DEPOSIT_FEE : 0;
            const depositsLessFees = sumOfDeposits.minus(sumOfDeposits.multipliedBy(fee));
            const sumOfWithdrawals = sumAmounts(withdrawals);
            const balanceDelta = depositsLessFees.minus(sumOfWithdrawals);
            const feesCollected = balance.minus(balanceDelta).minus(earnings);
            if (!prevFees.eq(feesCollected)) {
                setCollectedFees(feesCollected);
            }
        }
    }, [transactions, balance, earnings, underlyingToken, chainId]);

    useEffect(() => {
        // setLoadingBalance(true);
        setCollectedFees(new BigNumber(0));
    }, [underlyingToken]);

    const onWithdrawClick = () => {
        if (library && chainId) {
            withdrawer.setState(WithdrawerState.WAIT_FOR_INPUT);
        }
    };

    return (
        <>
            <div className="user-balance">
                <div className="user-balance-col">
                    <h3>{t('user-actions.your-balance').toUpperCase()}</h3>
                    <div className="value-row">
                        {showLoadingBalance || loadingBalance ? (
                            <div className="balance-loader">
                                <PuffLoader size={20} color="#09Af73" loading={true} />
                            </div>
                        ) : (
                            <>
                                <TokenSVG underlyingToken={underlyingToken} />
                                <span>{displayTokenBalance(wrapUnderlyingToken(underlyingToken), balance)}</span>
                            </>
                        )}
                    </div>
                </div>
                {/* <div className="user-balance-col">
                    <h3>{t('user-actions.your-earnings').toUpperCase()}</h3>
                    <div className="value-row">
                        {earningsLoading || showLoadingBalance || loadingBalance ? (
                            <div className="balance-loader">
                                <PuffLoader size={20} color="#09Af73" loading={true} />
                            </div>
                        ) : (
                            <>
                                <TokenSVG underlyingToken={underlyingToken} />
                                <span>{displayTokenBalance(wrapUnderlyingToken(underlyingToken), earnings)}</span>
                            </>
                        )}
                    </div>
                </div> */}
                <div className="user-balance-col">
                    <h3>{t('user-actions.your-fees-collected').toUpperCase()}</h3>
                    <div className="value-row">
                        {earningsLoading || showLoadingBalance || loadingBalance ? (
                            <div className="balance-loader">
                                <PuffLoader size={20} color="#09Af73" loading={true} />
                            </div>
                        ) : (
                            <>
                                <TokenSVG underlyingToken={underlyingToken} />
                                <span>{displayTokenBalance(wrapUnderlyingToken(underlyingToken), collectedFees)}</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="user-actions">
                <div className={`withdraw-btn ${true ? 'disabled' : ''}`} onClick={onDepositClick}>
                    {t('user-actions.holdings-user-deposit').toUpperCase()}
                </div>
                <div className={`withdraw-btn ${!library || !chainId ? 'disabled' : ''}`} onClick={onWithdrawClick}>
                    {t('user-actions.holdings-user-withdraw').toUpperCase()}
                </div>
                {/* <DepositModal underlyingToken={underlyingToken} /> */}
                <WithdrawModal underlyingToken={underlyingToken} />
            </div>
        </>
    );
}

function sumAmounts(txs: Tx[]): BigNumber {
    const sum = txs.reduce((sum, tx) => {
        return sum.plus(tx.amount);
    }, new BigNumber(0));

    return sum;
}
