import React, { ReactNode, useEffect } from 'react';
import Header from './Header';
import { IProps } from '../lib/types';

const AppWrapper: React.FC<IProps> = ({ children }: IProps) => {
    return (
        <div className="app">
            <Header />
            {children}
            {/* <Footer /> */}
        </div>
    );
};

export default AppWrapper;
