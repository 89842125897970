import React from 'react';
import { tokenSymbol } from '../../lib/tokens';

export default function TokenAmountTooltip({ active, payload, label }: any) {
    if (active && payload) {
        return (
            <div className="tooltip">
                <p className="label">{`${label} : ${payload[0].value.toString()} ${tokenSymbol(
                    payload[0].payload.token,
                )}`}</p>
            </div>
        );
    }
    return null;
}
