import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { networkIdFromChainId, NetworkID } from '../lib/network';
import wallet, { WalletModalState } from '../containers/wallet';
import WalletModal from './modals/WalletModal';
import { useTranslation } from 'react-i18next';

const WalletStatus = (): JSX.Element => {
    const { library, chainId, account } = useWeb3React();
    const disabled = library && networkIdFromChainId(chainId) === NetworkID.Unsupported ? true : false;
    const { setModalOpen } = wallet.useContainer();
    const { t } = useTranslation();

    const openModal = () => {
        setModalOpen(true);
    };

    return (
        <>
            {account ? (
                <div className="wallet-control" onClick={openModal}>
                    <span>{account.substring(0, 4) + '...' + account.slice(-4)}</span>
                </div>
            ) : (
                <div className={`connect-wallet-btn ${disabled ? 'disabled' : ''}`} onClick={openModal}>
                    {t('header.connect-wallet-btn')}
                </div>
            )}
            <WalletModal />
        </>
    );
};

export default WalletStatus;
