/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { UnderlyingToken, Token, displayTokenBalance } from '../lib/tokens';
import { useTranslation } from 'react-i18next';
import RookBalances, { calculateClaimable, ClaimRookState } from '../containers/rookBalances';
import ClaimRookModal from './modals/ClaimRookModal';
import BigNumber from 'bignumber.js';
import { ReactComponent as KprIcon } from '../styles/images/kpr-icon-green-small.svg';
import { useWeb3React } from '@web3-react/core';
import PuffLoader from 'react-spinners/PuffLoader';
import ExchangeRates from '../containers/exchangeRates';

type IBalances<T> = { [key in keyof typeof UnderlyingToken]: T };

const REWARDS_ENDED = true;

interface Props {
    exchange?: IBalances<BigNumber>;
}

enum BalanceType {
    claimable = 'claimable',
    supply = 'rookSupply',
    balance = 'rookBalance',
}

interface IBalanceList {
    [BalanceType.supply]: BigNumber;
    [BalanceType.balance]: BigNumber;
    [BalanceType.claimable]: BigNumber;
    error: null | string;
}

const RookWrapper: React.FC = () => {
    const { library, chainId } = useWeb3React();
    const { t } = useTranslation();
    const { rookBalances, setModalState, modalState } = RookBalances.useContainer();
    const { ROOK } = ExchangeRates.useContainer();
    const [serverSync, setServerSync] = useState(false);

    const [rookBalanceList, setRookBalancesList] = useState<IBalanceList>({
        [BalanceType.supply]: new BigNumber(0),
        [BalanceType.balance]: new BigNumber(0),
        [BalanceType.claimable]: new BigNumber(0),
        error: null,
    });

    useEffect(() => {
        const { totalRewardsPending, lp2block, hiding2Block, error } = calculateClaimable(rookBalances.claimable);

        const { currentBLock } = rookBalances;
        const DELTA = 500;

        if (!REWARDS_ENDED && (currentBLock - lp2block > DELTA || currentBLock - hiding2Block > DELTA)) {
            setServerSync(true);
        } else {
            setServerSync(false);
        }

        setRookBalancesList({
            [BalanceType.supply]: rookBalances.rookSupply,
            [BalanceType.balance]: rookBalances.rookBalance,
            [BalanceType.claimable]: totalRewardsPending,
            error: error,
        });
    }, [rookBalances, modalState]);

    const showBalance = (type: BalanceType, balance: BigNumber) => {
        return (
            <div className="rook-balance-row">
                <span className="rook-balance">{displayTokenBalance(Token.ROOK, balance)}</span>
                <KprIcon />
            </div>
        );
    };

    const openModal = () => {
        if (!rookBalanceList.error && !serverSync) {
            setModalState(ClaimRookState.WAIT_FOR_PROCEED_TO_CLAIMING);
        }
    };

    return (
        <div className="keeper-wrapper">
            {/* <div className="keeper-wrapper-row">
                <span className="rook-row-heading">{t('rook-rewards.supply').toUpperCase()}</span>
                {!rookBalanceList[BalanceType.supply].gt(0) ? (
                    <div className="rook-loader-row">
                        <PuffLoader size={20} color="#09Af73" loading={true} />
                    </div>
                ) : (
                    showBalance(BalanceType.supply, rookBalanceList[BalanceType.supply])
                )}
            </div>
            <div className="keeper-wrapper-row">
                <span className="rook-row-heading">{t('rook-rewards.balance').toUpperCase()}</span>
                {!rookBalanceList[BalanceType.supply].gt(0) ? (
                    <div className="rook-loader-row">
                        <PuffLoader size={20} color="#09Af73" loading={true} />
                    </div>
                ) : (
                    showBalance(BalanceType.balance, rookBalanceList[BalanceType.balance])
                )}
            </div>
            <div className="keeper-wrapper-row">
                <span className="rook-row-heading">{t('rook-rewards.rewards').toUpperCase()}</span>
                {!rookBalanceList[BalanceType.claimable].gte(0) ||
                !rookBalanceList[BalanceType.supply].gt(0) ||
                serverSync ? (
                    <div className="rook-loader-row">
                        {serverSync ? <span className="rook-server-sync-msg">Syncing</span> : null}
                        <PuffLoader size={20} color="#09Af73" loading={true} />
                    </div>
                ) : (
                    showBalance(BalanceType.claimable, rookBalanceList[BalanceType.claimable])
                )}
            </div>
            <div className="keeper-wrapper-row">
                <span className="rook-row-heading">{t('rook-rewards.rook-price').toUpperCase()}</span>
                {!rookBalanceList[BalanceType.supply].gt(0) ? (
                    <div className="rook-loader-row">
                        <PuffLoader size={20} color="#09Af73" loading={true} />
                    </div>
                ) : (
                    <div className="rook-balance-row">
                        <span className="rook-balance">${ROOK.toFormat(2)}</span>
                        <KprIcon />
                    </div>
                )}
            </div> */}
            {/* <div className="keeper-wrapper-row">
                <span className="rook-row-heading">{t('rook-rewards.rewards-end').toUpperCase()}</span>
                {!rookBalanceList[BalanceType.supply].gt(0) ? (
                    <div className="rook-loader-row">
                        <PuffLoader size={20} color="#09Af73" loading={true} />
                    </div>
                ) : (
                    <div className="rook-balance-row">
                        <span className="rook-balance">{rookBalances.rewardBLockEnd}</span>
                        {/* <span className="rook-balance-s">(3 Feb 2021)</span>
                        <KprIcon />
                    </div>
                )}
            </div> 
            */}
            {/* <div className="keeper-wrapper-row">
                <div
                    className={`withdraw-btn ${
                        !library || !chainId || rookBalanceList.error || serverSync ? 'disabled' : ''
                    }`}
                    onClick={openModal}
                >
                    {t('rook-rewards.claim-btn').toUpperCase()}
                </div>
            </div>
            <ClaimRookModal /> */}
        </div>
    );
};

export default RookWrapper;
