import React, { useState, useEffect } from 'react';
import { UnderlyingToken, wrapUnderlyingToken } from '../../lib/tokens';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { liquidityPoolTracesListRequest, tokenID } from '../../lib/graphql';
import {
    ChartLength,
    ChartDataType,
    getIntervalForLength,
    makeChartData,
    calculateRequiredBlocksHistory,
} from '../../lib/charts';
import { useWeb3React } from '@web3-react/core';
import TokenAmountTooltip from './TokenAmountTooltip';
import { useTranslation } from 'react-i18next';
import TokenSVG from '../TokenSVG';
import ClipLoader from 'react-spinners/ClipLoader';
import { networkIdFromChainId } from '../../lib/network';
import GraphInfo from '../../containers/graphInfo';
import BigNumber from 'bignumber.js';

interface Props {
    underlyingToken: {
        value: UnderlyingToken;
    } | null;
}

export default function DepositChart({ underlyingToken }: Props) {
    const { t } = useTranslation();
    const { library, chainId } = useWeb3React();
    const { library: networkWeb3, chainId: networkChainId } = useWeb3React('infura');
    const [chartHistoryLength, setChartHistoryLength] = useState<ChartLength>(ChartLength.all);
    const [dataHistory, setDataHistory] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { graphLatestBlock } = GraphInfo.useContainer();

    useEffect(() => {
        async function getBLocksForChartLength() {
            if ((!library && !networkWeb3) || (!chainId && !networkChainId)) {
                return;
            }
            if (!underlyingToken || !graphLatestBlock) {
                return;
            }
            setLoading(() => true);
            const web3 = !!library ? library : networkWeb3;
            const chain = library && chainId ? chainId : networkChainId;
            const net = networkIdFromChainId(chain);
            const requiredBLocks = calculateRequiredBlocksHistory(graphLatestBlock, chartHistoryLength, net);
            const { data, error } = await liquidityPoolTracesListRequest(
                requiredBLocks,
                tokenID(wrapUnderlyingToken(underlyingToken.value), net),
                net,
            );
            if (error) {
                setLoading(() => false);
                return;
            }
            setChartData(data);
        }
        getBLocksForChartLength();
    }, [chartHistoryLength, library, networkWeb3, chainId, networkChainId, graphLatestBlock, underlyingToken]);

    useEffect(() => {
        if (!underlyingToken) return;
        if (chartData && Object.keys(chartData).length) {
            const filtered = Object.keys(chartData)
                .reduce((acc: any[], item: string) => {
                    if (chartData[item].length > 0) {
                        const info = chartData[item][0];
                        acc.push(info);
                    }
                    return acc;
                }, [])
                .sort((a, b) => Number(a.updatedAt) - Number(b.updatedAt));
            setLoading(() => false);
            setDataHistory(
                makeChartData(
                    filtered,
                    wrapUnderlyingToken(underlyingToken.value),
                    ChartDataType.Balance,
                    chartHistoryLength,
                ),
            );
        }
    }, [chartData]);

    const lastValue =
        dataHistory && dataHistory.length > 0
            ? dataHistory[dataHistory.length - 1].value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            : 0;

    return (
        <div className="chart">
            <div className="chart-info">
                <div className="chart-info-row">
                    <div className="chart-info-col">
                        <h2>{t('summary.pool-total-supply').toUpperCase()}</h2>
                        <div className="value-row">
                            {underlyingToken && <TokenSVG underlyingToken={underlyingToken.value} />}
                            <span>{loading ? '0' : lastValue}</span>
                        </div>
                    </div>
                    <div className="chart-info-col">
                        <div className="chart-control">
                            <span
                                className={chartHistoryLength === ChartLength.day ? 'under' : ''}
                                onClick={() => setChartHistoryLength(ChartLength.day)}
                            >
                                24H
                            </span>
                            <span
                                className={chartHistoryLength === ChartLength.week ? 'under' : ''}
                                onClick={() => setChartHistoryLength(ChartLength.week)}
                            >
                                7D
                            </span>
                            <span
                                className={chartHistoryLength === ChartLength.all ? 'under' : ''}
                                onClick={() => setChartHistoryLength(ChartLength.all)}
                            >
                                ALL
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <>
                {loading ? (
                    <div className="spinner-wrapper">
                        <ClipLoader size={30} color="#09Af73" loading={loading} />
                    </div>
                ) : (
                    <ResponsiveContainer width={'100%'} height={250}>
                        <LineChart data={dataHistory} margin={{ right: 20, left: 20 }}>
                            <Tooltip content={<TokenAmountTooltip />} />
                            <XAxis
                                dataKey="name"
                                hide={false}
                                tickSize={7}
                                tick={{ fill: 'white' }}
                                interval={getIntervalForLength(chartHistoryLength, dataHistory?.length)}
                                width={30}
                                dy={5}
                            />
                            <YAxis
                                dataKey="value"
                                hide={true}
                                domain={[
                                    (dataMin) => 0 - Math.abs(dataMin * 0.3),
                                    (dataMax) => dataMax + Math.abs(dataMax * 0.3),
                                ]}
                            />
                            <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#09Af73"
                                strokeWidth={2.5}
                                dot={false}
                                activeDot={{ fill: '#AF094D', r: 5, className: 'dotShadow' }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </>
        </div>
    );
}
