import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { networkIdFromChainId, NetworkID } from '../lib/network';
import { useTranslation } from 'react-i18next';

const AppMessage = () => {
    const { library, chainId } = useWeb3React();
    const { library: networkWeb3, chainId: networkChainId } = useWeb3React('infura');

    const { t } = useTranslation();

    if (!library && networkWeb3 && networkChainId) {
        return (
            <div className="network-msg">
                <span>{t('app-message.connect-wallet')}</span>
            </div>
        );
    }

    if (!library && !networkWeb3 && !chainId && !networkChainId) {
        return (
            <div className="network-msg">
                <span>{t('app-message.connecting')}</span>
            </div>
        );
    }

    if (networkIdFromChainId(chainId) === NetworkID.Unsupported) {
        return (
            <div className="network-msg">
                <span>{t('app-message.connect-to-network')}</span>
            </div>
        );
    }

    return null;
};

export default AppMessage;
