import React, { useState, useMemo, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { TransactionType, Transaction, HistoryResponse } from '../containers/subscriptions/transactions';
import { NetworkID, networkIdFromChainId } from '../lib/network';
import CustomTable from './CustomTable';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { UnderlyingToken, tokenBalanceWithDecimals, tokenSymbol, wrapUnderlyingToken } from '../lib/tokens';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import config from '../lib/config';

interface Props {
    underlyingToken: UnderlyingToken;
    transactions: HistoryResponse | undefined;
    loading: boolean;
}

function makeRowsForTxsTable(txs: Transaction[], underlyingToken: UnderlyingToken, chainId: number | undefined) {
    const token = wrapUnderlyingToken(underlyingToken);

    const data = [
        ...txs
            .filter((tx) => tx.token === token && tx.type !== TransactionType.BorrowType)
            .map(({ type, amount, hash, time, token }) => {
                const fee = chainId === 1 && type === TransactionType.DepositType ? config.MAINNET_DEPOSIT_FEE : 0;
                const amountLessFee = amount.minus(amount.multipliedBy(fee));
                const date = moment.unix(time).format('YYYY-M-D HH:mm:ss');
                const sign = type === TransactionType.DepositType ? '+' : '-';
                const change = `${sign}${tokenBalanceWithDecimals(token, amountLessFee)} ${tokenSymbol(token)}`;
                return {
                    col1: date,
                    col2: change,
                    col3: `${hash.slice(0, 10)}...${hash.slice(-10)}`,
                    hash,
                };
            }),
    ];

    return data;
}

export default function History({ underlyingToken, transactions, loading }: Props): JSX.Element {
    const { t } = useTranslation();
    const { chainId } = useWeb3React();
    const columns = useMemo(() => {
        return [
            {
                Header: t('table-history.date').toUpperCase(),
                accessor: 'col1',
            },
            {
                Header: t('table-history.change').toUpperCase(),
                accessor: 'col2',
                sortType: 'basic',
            },
            {
                Header: t('table-history.hash').toUpperCase(),
                accessor: 'col3',
                sortType: 'basic',
            },
        ];
    }, [t]);
    const [list, setList] = useState(() => makeRowsForTxsTable([], underlyingToken, chainId));

    const handleRowClick = (row: { original: any }) => {
        const hash = row.original.hash;
        const link = `https://${
            networkIdFromChainId(chainId) !== NetworkID.Mainnet ? `${networkIdFromChainId(chainId)}.` : ''
        }etherscan.io/tx/${hash}`;
        window.open(link, '_blank');
    };

    useEffect(() => {
        if (!transactions) {
            return;
        }
        const txs = parseTxs(transactions, underlyingToken);
        setList(makeRowsForTxsTable(txs, underlyingToken, chainId));
    }, [underlyingToken, transactions, chainId]);

    // if (loading) {
    //     return (
    //         <>
    //             <ClipLoader size={30} color="#09Af73" loading={loading} />
    //         </>
    //     );
    // }

    return (
        <>
            <CustomTable data={list} columns={columns} onRowClick={handleRowClick} />
        </>
    );
}

export function parseTxs(data: HistoryResponse, token: UnderlyingToken): Transaction[] {
    const deposits = data?.deposits;
    const withdrawals = data?.withdrawals;

    const mapedDeposits: Transaction[] = deposits?.map((d) => ({
        type: TransactionType.DepositType,
        amount: new BigNumber(d.amount),
        token: wrapUnderlyingToken(token),
        hash: d.id,
        time: parseInt(d.depositedAt),
    }));

    const mapedWithdrawals: Transaction[] = withdrawals?.map((d) => ({
        type: TransactionType.WithdrawType,
        amount: new BigNumber(d.amount),
        token: wrapUnderlyingToken(token),
        hash: d.id,
        time: parseInt(d.withdrewAt),
    }));

    return [...mapedDeposits, ...mapedWithdrawals].sort((a, b) => b.time - a.time);
}

function parseTxType(txType: string): TransactionType {
    switch (txType) {
        case 'Deposit':
            return TransactionType.DepositType;
        case 'Withdraw':
            return TransactionType.WithdrawType;
        case 'Borrow':
            return TransactionType.BorrowType;
        default:
            throw new TypeError('non-exhaustive pattern');
    }
}
