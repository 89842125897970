import { useState, useEffect, useCallback } from 'react';
import { createContainer } from 'unstated-next';
import { useWeb3React } from '@web3-react/core';
import { getUniswapLatestBlockNumber } from '../lib/graphql';
import { networkIdFromChainId, NetworkID } from '../lib/network';

const SAFE_GRAPH_LAG = 30;

function useGraphInfo() {
    const [graphLatestBlock, setGraphLatestBlock] = useState<number>(0);
    const { library, chainId } = useWeb3React();
    const { library: networkWeb3, chainId: networkChainId } = useWeb3React('infura');

    async function updateBlockHeight() {
        const web3 = library ? library : networkWeb3;
        if (!web3) return;

        const chain = library && chainId ? chainId : networkChainId;
        const net = networkIdFromChainId(chain);

        if (net === NetworkID.Mainnet) {
            const uniswapCurrentBlock = await getUniswapLatestBlockNumber();
            setGraphLatestBlock(uniswapCurrentBlock);
            return;
        }
        // console.log('Update block number');
        const graphBlock = parseInt(await web3.eth.getBlockNumber()) - SAFE_GRAPH_LAG;
        setGraphLatestBlock(graphBlock);
    }

    useEffect(() => {
        //update first
        updateBlockHeight();

        //then every 2 min
        const interval = setInterval(() => {
            updateBlockHeight();
        }, 2 * 60 * 1000);
        return () => clearInterval(interval);
    }, [library, networkWeb3, chainId, networkChainId]);

    return { graphLatestBlock, updateBlockHeight };
}

const GraphInfo = createContainer(useGraphInfo);

export default GraphInfo;
