import React, { useState, useEffect } from 'react';
import Select, { ValueType } from 'react-select';

import { useTranslation } from 'react-i18next';

type OptionType = { label: string; value: string };

const options: OptionType[] = [
    { value: 'en', label: 'ENGLISH' }, //🇬🇧
    { value: 'ch', label: '简体中文' }, //🇨🇳
    { value: 'kr', label: '한국어' }, //🇰🇷
    { value: 'ru', label: 'Русский' }, //🇷🇺
];

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [value, setValue] = useState(options[0]);

    useEffect(() => {
        // Update the state when the language has changed.
        const valuesList = options.filter((option) => option.value === i18n.language);
        if (valuesList.length > 0) {
            setValue(valuesList[0]);
        }
    }, [i18n.language]);

    const changeLanguage = (event: ValueType<OptionType, boolean>) => {
        i18n.changeLanguage((event as OptionType).value);
    };

    return (
        <Select
            className="languages"
            options={options}
            value={value}
            isSearchable={false}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            onChange={changeLanguage}
            theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                    ...theme.colors,
                    primary25: '#f0f2f3',
                    primary50: '#f0f2f3',
                    primary: '#f0f2f3',
                },
            })}
            styles={customStyles}
        />
    );
};

const customStyles = {
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        justifyContent: 'center',
        padding: '0',
        height: '35px',
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#7a7a7a',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: 'white',
        backgroundColor: state.isSelected ? 'grey' : '#7a7a7a',
        '&:hover': {
            backgroundColor: '#09AF73',
        },
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: '#1E1E1C',
        height: '35px',
        color: 'white',
        border: 0,
    }),

    singleValue: (provided: any) => ({
        ...provided,
        color: 'white',
        margin: 0,
    }),
};

export default LanguageSelector;
