import { createContainer } from 'unstated-next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import analytics from '../lib/analytics';

function useGoogleAnalytics() {
    const location = useLocation();
    const { init, sendEvent, sendPageview } = analytics;

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const currentPath = location.pathname + location.search;
        sendPageview(currentPath);
    }, [location]);

    return { sendEvent, sendPageview };
}

const GA = createContainer(useGoogleAnalytics);

export default GA;
