import ReactGA from 'react-ga';

import config from './config';

export const EVENT_CATEGORIES = {
    WALLET: 'Wallet',
    POOL_BALANCE: 'Pool balance',
    CLAIM: 'Claim',
};

export const ACTIONS = {
    WALLET_CHANGED: 'Wallet changed',
    DEPOSIT: 'Deposit',
    WITHDRAW: 'Withdraw',
    CLAIM_REWARD: 'Claim reward',
};

interface IEvent {
    category: string;
    action: string;
    value?: number;
    label?: string;
    nonInteraction?: boolean;
}

function init(): void {
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    ReactGA.initialize(config['GOOGLE_ANALYTICS_ID'], { debug: isDev });
}

function sendEvent(payload: IEvent): void {
    ReactGA.event(payload);
}

function sendPageview(path: string): void {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
}

export default {
    init,
    sendEvent,
    sendPageview,
};
