import { useState } from 'react';
import { createContainer } from 'unstated-next';
import BigNumber from 'bignumber.js';
import { Token } from '../lib/tokens';

export enum BorrowState {
    HIDDEN,
    // ACCEPT_FEE,
    WAIT_FOR_INPUT,
    WAIT_FOR_APPROVE,
    SET_UNLIMITED_APPROVAL,
    WAIT_FOR_BORROW,
    SUCCESS,
    ERROR,
    CANCEL_APPROVE,
    CANCEL_BORROW,
}

export enum SupplyState {
    HIDDEN,
    // ACCEPT_FEE,
    WAIT_FOR_INPUT,
    WAIT_FOR_APPROVE,
    SET_UNLIMITED_APPROVAL,
    WAIT_FOR_SUPPLY,
    SUCCESS,
    ERROR,
    CANCEL_APPROVE,
    CANCEL_SUPPLY,
}

function useBorrower() {
    const [amount, setAmount] = useState(null as BigNumber | null);
    const [token, setToken] = useState<Token>(Token.DAI);
    const [state, setState] = useState(BorrowState.HIDDEN);

    function openBorrowModal(token: Token) {
        setToken(token);
        setState(BorrowState.WAIT_FOR_INPUT);
    }

    return {
        amount,
        setAmount,
        state,
        setState,
        token,
        setToken,
        openBorrowModal,
    };
}

function useSupplier() {
    const [amount, setAmount] = useState(null as BigNumber | null);
    const [token, setToken] = useState<Token>(Token.DAI);
    const [state, setState] = useState(SupplyState.HIDDEN);

    function openSupplyModal(token: Token) {
        setToken(token);
        setState(SupplyState.WAIT_FOR_INPUT);
    }

    return {
        amount,
        setAmount,
        state,
        setState,
        token,
        setToken,
        openSupplyModal,
    };
}

export const Borrower = createContainer(useBorrower);
export const Supplier = createContainer(useSupplier);
