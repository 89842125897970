import { useState } from 'react';
import { createContainer } from 'unstated-next';
import BigNumber from 'bignumber.js';

export enum WithdrawerState {
    HIDDEN,
    WAIT_FOR_INPUT,
    SET_UNLIMITED_APPROVAL,
    WAIT_FOR_APPROVE,
    WAIT_FOR_WITHDRAW,
    SUCCESS,
    ERROR,
    CANCEL_APPROVE,
    CANCEL_WITHDRAW,
}

function useWithdrawer() {
    const [amount, setAmount] = useState(null as BigNumber | null);
    const [state, setState] = useState(WithdrawerState.HIDDEN);
    return {
        amount, setAmount,
        state, setState,
    };
}

const Withdrawer = createContainer(useWithdrawer);

export default Withdrawer;
