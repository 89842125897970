import React from 'react';
import LanguageSelector from './LanguageSelector';
import { ReactComponent as Logo } from '../styles/images/KeeperDAO_Logo_Inline_White.svg';
import WalletStatus from './WalletStatus';
import AppMessage from './AppMessage';

export default function Header(): any {
    return (
        <div className="header">
            <AppMessage />
            <div className="container">
                <div className="header-item left">
                    <LanguageSelector />
                    <div className="legacy-btn" onClick={() => window.open('http://app.keeperdao.com/', '_blank')}>
                        <span>ACCESS NEW APP</span>
                    </div>
                </div>
                <div className="header-logo-wrapper">
                    <a href="https://keeperdao.com" target="_blank" rel="noopener noreferrer">
                        <Logo className="logo-center" />
                    </a>
                    <div
                        className="legacy-btn-mobile"
                        onClick={() => window.open('http://app.keeperdao.com/', '_blank')}
                    >
                        <span>ACCESS NEW APP</span>
                    </div>
                </div>

                <div className="header-item right">
                    <WalletStatus />
                </div>
            </div>
        </div>
    );
}
